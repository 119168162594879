<template>
  <div>

    <steps :passive-steps="[4, 5, 6, 7]" :active-step="3" :finished-steps="[1, 2]"/>

      <section class="section">
        <div class="container has-text-centered">
          <h1 class="title is-3">{{ $t('Step 3. Order form') }}</h1>
        </div>
      </section>
      <section class="section">
        <div class="container">
          <div class="content">
            <div class="columns is-centered">
              <div class="column is-6">
                <form action="/">
                  <div class="field">
                    <label class="label">{{ $t('User Type') }}*</label>
                    <div class="select is-fullwidth">
                      <select name="role"  v-model="selectedUserId" disabled>
                        <option value="4">{{ $t('Corporate customer') }}</option>
                      </select>
                    </div>
                  </div>
                  <div style="height:1rem;"></div>
                  <div class="field">
                    <label class="label">{{ $t('Select the country') }}*</label>
                    <div class="select is-fullwidth">
                      <select name="country" v-model="selectedCountryId" disabled>
                        <option :value="countryId">{{countryName}}</option>
                      </select>
                    </div>
                  </div>
                  <div style="height:1rem;"></div>
                  <div class="field">
                    <label class="label">{{ $t('Identification number') }}*</label>
                    <div class="control">
                      <input v-model="docValue" name="docValue" type="text" class="input" disabled>
                    </div>
                  </div>
                  <div style="height:1.5rem;"></div>
                  <div class="field">
                    <label class="label">{{ $t('Scan-copy of the document') }}*</label>
                    <div class="control">
                      <a v-on:click="getDoc()">{{ $t('Document...') }}</a>
                    </div>
                  </div>
                  <div style="height:1rem;"></div>
                  <div class="field">
                    <label class="label">{{ $t('Company name form') }}:*</label>
                    <div class="control">
                      <input v-model="orgName" name="orgName" type="text" class="input" disabled>
                    </div>

                    <div style="height: 1rem;"></div>

                  </div>

                  <div class="buttons">
                    <input class="button is-primary is-large" type="button" v-on:click="GoToStepTreeDotOne" value="Далее →">
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

  </div>
</template>

<script>
import steps from '../steps/Steps'
import fileSaver from 'file-saver'
export default {
  name: 'OrderStepThreeDotZero',
  components: {
    steps
  },
  props: [
    'currentForm',
    'userParams'
  ],
  data () {
    return {
      selectedUserId: '4',
      selectedCountryId: null,
      countryId: null,
      countryName: null,
      docValue: null,
      orgName: null,
      scanId: null,
      scanName: null
    }
  },
  methods: {
    GoToStepTreeDotOne () {
      this.$emit('update:currentForm', 'FormStepThreeDotOne')
    },
    getDoc () {
      this.$http.post('/certificate/get-scan', { scan: this.scanId }, { responseType: 'blob' }).then(response => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          fileSaver.saveAs(blob, this.scanName)
        }
      })
    }
  },
  watch: {
    userParams: {
      handler: function (val) {
        if (val) {
          this.$http.post('/certificate/get-scan-name', { scan: val.scan }).then(response => {
            if (response.data) {
              console.log('scanName=' + response.data)
              this.scanName = response.data
            }
          })
          let re = /\s*_\s*/
          let country = val.country.split(re)
          this.selectedCountryId = country[0]
          this.countryId = country[0]
          this.countryName = country[2]
          this.docValue = val.docnum
          this.orgName = val.name
          this.scanId = val.scan
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
  .file-name {
    max-width: 700px !important;
  }
  .has-user-border {
    border: 1px solid #339968;
  }
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
</style>
