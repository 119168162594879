<template>
  <div>
<!--    <pre style="font-size: 30px">{{reissueStatus}}</pre>-->
    <steps :passive-steps="[4, 5, 6, 7]" :active-step="3" :finished-steps="[1, 2]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Step 3. Order form') }}</h1>
        <h2 class="title is-4">{{ $t('Invoice') }}</h2>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-4">
<!--              <pre v-if="myTemplate">{{myTemplate}}</pre>-->
<!--                <pre>{{selectedCurrency}}</pre>-->
              <div class="field">
                <label class="label">{{ $t('Currency') }}*</label>
                <div class="select is-fullwidth">
                  <select v-model="selectedCurrency" @change="ChangeCurrency()">
                    <option value="KZT">{{ $t('Tenge') }}</option>
                    <option value="RUB">{{ $t('Rubles') }}</option>
                    <option value="USD">{{ $t('Dollars') }}</option>
                    <option value="EUR">{{ $t('Euro') }}</option>
                  </select>
                </div>
              </div>
              <div style="height: 1rem;"></div>
              <div class="field">
                <label class="label">{{ $t('Bill language') }}*</label>
                <div class="select is-fullwidth">
                  <select v-model="selectedBillLang">
                    <option value="ru">{{ $t('Russian') }}</option>
                    <option value="en">{{ $t('English') }}</option>
                  </select>
                </div>
              </div>

              <div style="height: 5rem;"></div>

              <div class="buttons">
                <input class="button is-large" type="button" v-on:click="GoToStepThreeDotOne" v-bind:value="$t('← Back')">
                <input class="button right is-primary is-large" type="button" v-on:click="GoToStepThreeDotThree" v-bind:value="$t('Next →')">
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import steps from '../steps/Steps'

export default {
  name: 'OrderStepThreeDotTwo',
  components: {
    steps
  },
  props: [
    'currentForm',
    'dataStepThreeDotTwo'
  ],
  data () {
    return {
      selectedBillLang: 'ru',
      selectedCurrency: localStorage.getItem('currency') ? localStorage.getItem('currency') : 'KZT',
      currencies: null,
      price: localStorage.getItem('price'),
      currencyId: localStorage.getItem('currencyId')
    }
  },
  watch: {
    currencies: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/currencies/').then(response => {
            this.currencies = response.data
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    getCertType () {
      return window.localStorage.getItem('getCertType') ? window.localStorage.getItem('getCertType') : 'new'
    },
    // selectedCurrency: {
    //   get() {
    //     return localStorage.getItem('currency') ? localStorage.getItem('currency') : 'KZT'
    //   },
    //   set(newValue) {
    //     const currency = this.currencies ? this.currencies.find(item => item.designation === newValue) : null
    //     window.localStorage.setItem('currency', newValue)
    //     window.localStorage.setItem('currencyId', currency.id)
    //     window.localStorage.setItem('course', currency.course)
    //     console.log(newValue)
    //     return newValue
    //   }
    // }
    myTemplate () {
      return this.$store.state.myTemplate
    }
  },
  methods: {
    ChangeCurrency () {
      const currency = this.currencies ? this.currencies.find(item => item.designation === this.selectedCurrency) : null
      window.localStorage.setItem('currency', this.selectedCurrency)
      if (this.selectedCurrency == 'RUB') {
        this.currencyId = 6
        if (this.getCertType == 'new') { this.price = this.myTemplate.price_rur } else if (this.getCertType == 'reissue') { this.price = this.myTemplate.recover_rur } else { this.price = this.myTemplate.revoke_rur }
      } else if (this.selectedCurrency == 'USD') {
        this.price = this.myTemplate.price_usd
        this.currencyId = 5
        if (this.getCertType == 'new') { this.price = this.myTemplate.price_usd } else if (this.getCertType == 'reissue') { this.price = this.myTemplate.recover_usd } else { this.price = this.myTemplate.revoke_usd }
      } else if (this.selectedCurrency == 'EUR') {
        this.price = this.myTemplate.price_eur
        this.currencyId = 7
        if (this.getCertType == 'new') { this.price = this.myTemplate.price_eur } else if (this.getCertType = 'reissue') { this.price = this.myTemplate.recover_eur } else { this.price = this.myTemplate.revoke_eur }
      } else {
        this.price = this.myTemplate.price_kzt
        this.currencyId = 4
        if (this.getCertType == 'new') { this.price = this.myTemplate.price_kzt } else if (this.getCertType == 'reissue') { this.price = this.myTemplate.recover_kzt } else { this.price = this.myTemplate.revoke_kzt }
      }
      localStorage.setItem('price', this.price)
    },
    GoToStepThreeDotOne () {
      this.$emit('update:currentForm', 'FormStepThreeDotOne')
    },
    GoToStepThreeDotThree () {
      this.$emit('update:dataStepThreeDotTwo', {
        currency: localStorage.getItem('currency'),
        currencyId: this.currencyId,
        price: this.price,
        billLang: this.selectedBillLang,
        oid: localStorage.getItem('templateId')
      })
      this.$emit('update:currentForm', 'FormStepThreeDotThree')
    }
  }
}
</script>

<style scoped lang="scss">
  .right {
    margin-left: 20px;
  }
  .has-user-border {
    border: 1px solid #339968;
  }
</style>
