<template>
  <div>

    <steps :passive-steps="[5, 6, 7]" :active-step="4" :finished-steps="[1, 2, 3]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Step 4. Keys are being formed') }}</h1>
        <h2 class="title is-4">{{ $t('Connection to the security token') }}</h2>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-6">

              <label class="label ">{{ $t('Enter the password to access the security token') }}*:</label>

              <div class="field has-addons">
                <div class="control">
                  <input v-model.trim="password" name="password" v-validate="'required'" class="input is-medium pass" :class="{'has-error': errors.has('password')}"  type="password" v-bind:placeholder="$t('Enter the password')">
                </div>
                <div class="control">
                  <a class="button is-primary is-medium" :class="{'is-loading': isLoading}" v-on:click="GoToStepFourDotTwo">{{buttonPass}}</a>
                </div>
              </div>
              <p v-if="errors.has('password')" class="alert-error ">{{errors.first('password')}}</p>

              <p v-if="mess" class="alert-error ">{{mess}}</p>
              <TokenHelpText v-if="tokenPage" param="step"/>
              <p class="help" v-html="helpText"></p>

              <div style="height: 2rem;"></div>
              <input class="button is-large" type="button" v-on:click="GoToStepFour" v-bind:value="$t('← Back')">

              <div style="height: 3rem;"></div>
              <keyProblem/>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import steps from '../steps/Steps'
import keyProblem from '../components/KeyProblem'
import TokenHelpText from '../steps/TokenHelpPage'
export default {
  name: 'OrderStepFourDotOne',
  components: {
    steps,
    keyProblem,
    TokenHelpText
  },
  props: [
    'currentForm',
    'dataStepFourDotOne'
  ],
  data () {
    return {
      orderModalActive: false,
      password: null,
      mess: null,
      helpText: this.$i18n.t('If the number of incorrect password attempts to exceed the allowed number (by default - three), the security token will be locked').toString(),
      devices: null,
      buttonPass: this.$i18n.t('Send the request →').toString(),
      isLoading: false,
      tokenPage: null,
    }
  },
  methods: {
    openOrderModal () {
      this.orderModalActive = !this.orderModalActive
    },
    GoToStepFour () {
      this.$emit('update:currentForm', 'FormStepFour')
    },
    goNext () {
      this.$emit('update:currentForm', 'FormStepFourDotTwo')
      this.$emit('update:dataStepFourDotOne', {
        password: this.password,
      })
    },
    async check () {
      try {
        this.devices = await this.cryptoSocket.getDevices({ id: 2 })
        await this.cryptoSocket.CheckPassword({ id: 1, reader: this.devices.devices[0].reader, pass: this.password, alg: 'GOST' })
        await this.cryptoSocket.CheckPassword({ id: 2, reader: this.devices.devices[0].reader, pass: this.password, alg: 'RSA' })

        this.goNext()
      } catch (error) {
        this.isLoading = false
        this.buttonPass = this.$i18n.t('Send the request →').toString()
        if (error.Function === 'getDevices') {
          this.mess = this.$i18n.t('Security token not found or not supported').toString()
          this.tokenPage = true
        }
        if (error.Function === 'CheckPassword') {
            this.mess = this.$i18n.t('Security token access error, maybe the password is invalid').toString()
            this.helpText = this.$i18n.t('If the number of incorrect password attempts to exceed the allowed number (by default - three), the security token will be locked').toString()
        }
      }
    },
    GoToStepFourDotTwo () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isLoading = true
          this.buttonPass = ''
          console.log('fourDotOne')
          this.check()
        }
      }).catch(error => console.log(error.message))
    }
  }
}
</script>

<style scoped lang="scss">
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
  .pass {
    width: 250px;
  }
  .has-error {
    border-color: red;
  }
  .modal-card-title{
    width: 80%;
    color:red;
  }
</style>
