<template>
  <div>

    <steps :passive-steps="[4, 5, 6, 7]" :active-step="3" :finished-steps="[1, 2]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Step 3. Order form') }}</h1>
        <h2 class="title is-4">{{ $t('Data verification') }}</h2>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-6">
              <article class="message is-primary">
                <div class="message-header">
                  <p>{{ $t('Data verification') }}</p>
                </div>
                <div class="message-body has-user-border">
                  <table>
                    <tbody>
                    <tr>
                      <td class="is-3"><strong>{{ $t('Customer') }}</strong></td>
                      <td class="is-9"></td>
                    </tr>
                    <tr>
                      <td>{{ $t('Full name') }}:</td>
                      <td>{{params.fio}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Country') }}:</td>
                      <td>{{params.country}}</td>
                    </tr>
                    <tr>
                      <td class="is-3">{{ $t('Company name form') }}:</td>
                      <td class="is-9">{{params.company}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Identification number') }}:</td>
                      <td>{{params.doc}}</td>
                    </tr>

                    <tr>
                      <td>{{ $t('Email') }}:</td>
                      <td>{{params.email}}</td>
                    </tr>
                    <tr>
                      <td class="is-3"><strong>{{ $t('Certificate') }}</strong></td>
                      <td class="is-9"></td>
                    </tr>
                    <tr>
                      <td>{{ $t('Type') }}:</td>
                      <td>{{templateName}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Validity') }}:</td>
                      <td>{{templateValidity}}</td>
                    </tr>
                    <tr v-if="templateType !== 'Test'">
                      <td>{{ $t('Currency') }}:</td>
                      <td>{{currency}}</td>
                    </tr>
                    <tr v-if="templateType !== 'Test'">
                      <td>{{ $t('Bill language') }}:</td>
                      <td>{{billLang}}</td>
                    </tr>
                    <tr v-if="templateType !== 'Test'">
                      <td>{{ $t('Value') }}:</td>
                      <td><b>{{price}}</b></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </article>

              <div style="height: 2rem;"></div>

              <div class="buttons">
                <input class="button is-large" type="button" v-on:click="GoToStepThree" v-bind:value="$t('← Edit')">
                <input class="button right is-primary is-large" type="button" v-on:click="GoToStepFour" v-bind:value="$t('Next →')">
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import steps from '../steps/Steps'
export default {
  name: 'OrderStepThreeDotThree',
  components: {
    steps
  },
  props: [
    'currentForm',
    'paramsForStepThreeDotThree',
    'sRole'
  ],
  data () {
    return {
      template: null,
      templatePrice: null
    }
  },
  watch: {
    paramsForStepThreeDotThree: {
      handler: function (val) {
        if (val) {
          if (val.certType) {
            this.$http.get('/query/get-oid/', { params: { id: val.certType } }).then(response => {
              if (response.data) {
                this.template = response.data
              }
            })
          }
          if (val.currency) {
            this.$http.get('/query/get-price/', { params: { id: val.currency } }).then(response => {
              if (response.data) {
                this.templatePrice = response.data
              }
            })
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    params () {
      return this.paramsForStepThreeDotThree ? this.paramsForStepThreeDotThree : null
    },
    templateName () {
      if (this.lang == 'ru') {
        return localStorage.getItem('templateDescription_ru') ? localStorage.getItem('templateDescription_ru') : null
      } else if (this.lang == 'en') {
        return localStorage.getItem('templateDescription_en') ? localStorage.getItem('templateDescription_en') : null
      } else if (this.lang == 'kk') {
        return localStorage.getItem('templateDescription_kk') ? localStorage.getItem('templateDescription_kk') : null
      } else {
        return null
      }
    },
    templateValidity () {
      return localStorage.getItem('templateValidity') ? localStorage.getItem('templateValidity') : (this.template ? (this.lang = 'ru' ? this.template.validity : this.template.validity_en) : null)
    },
    currency () {
      return localStorage.getItem('currency') ? localStorage.getItem('currency') : null
    },
    price () {
      return localStorage.getItem('price') ? localStorage.getItem('price') + ' ' + localStorage.getItem('currency') : null
    },
    billLang () {
      return this.params.billLang ? (this.params.billLang === 'ru' ? this.$i18n.t('Russian') : this.$i18n.t('English')) : null
    },
    templateType () {
      return localStorage.getItem('template')
    }
  },
  methods: {
    GoToStepThree () {
      this.$emit('update:currentForm', 'FormStepThree')
    },
    GoToStepFour () {
      if ((localStorage.getItem('template') && localStorage.getItem('template') === 'Test') || (localStorage.getItem('getCertType') === 'reissue')) {
        this.$emit('update:currentForm', 'FormStepFour')
      } else {
        this.$http.get('/query/check-price/', {
          params: {
            template_name: localStorage.getItem('template'),
            price: localStorage.getItem('price')
          }
        }).then(res => {
          if (res.data && res.data == true) {
            this.$emit('update:currentForm', 'FormStepFour')
          }
        }).catch(e => {
          console.log('Problem with price')
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .right {
    margin-left: 20px;
  }
</style>
