<template>
  <div>
    <OrderStepOne
      v-if="currentForm === 'FormStepOne' || step"
      v-bind:current-form.sync="currentForm"
      v-bind:certificate-name.sync="certificateName"
      v-bind:s-role="role"
    />
    <OrderStepTwo
      v-if="currentForm === 'FormStepTwo' && !step"
      v-bind:current-form.sync="currentForm"
    />
    <OrderStepThree
      v-if="currentForm === 'FormStepThree' && !step"
      v-bind:current-form.sync="currentForm"
      v-bind:data-step-three.sync="dataStepThree"
      v-bind:reissue-params="reissueParams"
      v-bind:edit-order-params="editOrderParams"
      v-bind:set-cert-form="setCertForm"
    />
    <OrderStepThreeDotZero
      v-if="currentForm === 'FormStepThreeDotZero' && !step"
      v-bind:current-form.sync="currentForm"
      v-bind:user-params="userParams"
    />
    <OrderStepThreeDotOne
      v-if="currentForm === 'FormStepThreeDotOne' && !step"
      v-bind:current-form.sync="currentForm"
      v-bind:role.sync="role"
      v-bind:doc="doc"
      v-bind:user-params="userParams"
      v-bind:data-step-three-dot-one.sync="dataStepThreeDotOne"
      v-bind:reissue-params="reissueParams"
      v-bind:edit-order-params="editOrderParams"
      v-bind:set-cert-form="setCertForm"
    />
    <OrderStepThreeDotTwo
      v-if="currentForm === 'FormStepThreeDotTwo' && !step"
      v-bind:current-form.sync="currentForm"
      v-bind:data-step-three-dot-two.sync="dataStepThreeDotTwo"
    />
    <OrderStepThreeDotThree
      v-if="currentForm === 'FormStepThreeDotThree' && !step"
      v-bind:current-form.sync="currentForm"
      v-bind:s-role.sync="role"
      v-bind:params-for-step-three-dot-three="paramsForStepThreeDotThree"
    />
    <OrderStepFour
      v-if="currentForm === 'FormStepFour' && !step"
      v-bind:current-form.sync="currentForm"
      v-bind:s-role="role"
    />
    <OrderStepFourDotOne
      v-if="currentForm === 'FormStepFourDotOne' && !step"
      v-bind:current-form.sync="currentForm"
      v-bind:data-step-four-dot-one.sync="dataStepFourDotOne"
    />
    <OrderStepFourDotTwo
      v-if="currentForm === 'FormStepFourDotTwo' && !step"
      v-bind:current-form.sync="currentForm"
      v-bind:params-for-order="paramsForOrder"
      v-bind:data-step-four-dot-two.sync="dataStepFourDotTwo"
    />
    <OrderStepFourDotThree
      v-if="currentForm === 'FormStepFourDotThree' && !step"
      v-bind:current-form.sync="currentForm"
      v-bind:params-for-step-four-dot-three="paramsForStepFourDotThree"
      v-bind:data-step-four-dot-three.sync="dataStepFourDotThree"
    />
    <OrderStepFive
      v-if="currentForm === 'FormStepFive' && !step"
      v-bind:current-form.sync="currentForm"
      v-bind:params-for-step-five="paramsForStepFive"
      v-bind:status="status"
      v-bind:page="page"
    />
    <OrderStepLoading
      v-if="currentForm === 'FormStepLoading' && !step"
      v-bind:current-form.sync="currentForm"
    />
<!--    <pre>{{templates}}</pre>-->
  </div>
</template>

<script>
import OrderStepOne from '../steps/OrderStepOne'
import OrderStepTwo from '../steps/OrderStepTwo'
import OrderStepThree from '../steps/OrderStepThree'
import OrderStepThreeDotZero from '../steps/OrderStepThreeDotZero'
import OrderStepThreeDotOne from '../steps/OrderStepThreeDotOne'
import OrderStepThreeDotTwo from '../steps/OrderStepThreeDotTwo'
import OrderStepThreeDotThree from '../steps/OrderStepThreeDotThree'
import OrderStepFourDotThree from '../steps/OrderStepFourDotThree'
import OrderStepFour from '../steps/OrderStepFour'
import OrderStepFourDotOne from '../steps/OrderStepFourDotOne'
import OrderStepFourDotTwo from '../steps/OrderStepFourDotTwo'
import OrderStepFive from '../steps/OrderStepFive'
import OrderStepLoading from '../steps/OrderStepLoading'
export default {
  name: 'OrderCert',
  components: {
    OrderStepOne,
    OrderStepTwo,
    OrderStepThree,
    OrderStepThreeDotZero,
    OrderStepThreeDotOne,
    OrderStepThreeDotTwo,
    OrderStepThreeDotThree,
    OrderStepFour,
    OrderStepFourDotOne,
    OrderStepFourDotTwo,
    OrderStepFourDotThree,
    OrderStepFive,
    OrderStepLoading
  },
  created () {
    if (!this.corporate && (!localStorage.getItem('currencyId') || !localStorage.getItem('currency') || !localStorage.getItem('template') || !localStorage.getItem('templateId'))) {
      this.$router.push('/getcertificate')
    }
  },
  data () {
    return {
      currentForm: 'FormStepOne',
      dataStepThree: null,
      dataStepThreeDotOne: null,
      dataStepThreeDotTwo: null,
      dataStepFourDotOne: null,
      dataStepFourDotTwo: null,
      dataStepFourDotThree: null,
      objectName: null,
      status: null,
      page: null,
      userParams: null,
      reissueParams: null,
      editOrderParams: null,
      certificateName: null,
      setCertForm: null,
      role: null,
      result: null,
      templates: null,
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    corporate () {
      return this.$store.state.corporate
    },
    step () {
      return this.$store.state.orderStep ? this.$store.state.orderStep : ''
    },
    orderNumber () {
      // return this.$store.state.orderNumber ? this.$store.state.orderNumber : ''
      return localStorage.getItem('OrderNumber') ? localStorage.getItem('OrderNumber') : ''
    },
    orderPassword () {
      // return this.$store.state.orderPassword ? this.$store.state.orderPassword : ''
      return localStorage.getItem('OrderPassword') ? localStorage.getItem('OrderPassword') : ''
    },
    stepToNext () {
      return this.$route.params.step ? this.$route.params.step : ''
    },
    editOrder () {
      return this.$route.query.order ? this.$route.query.order : ''
    },
    reissue () {
      // return this.$store.state.order ? this.$store.state.order : ''
      return localStorage.getItem('order') ? localStorage.getItem('order') : ''
    },
    template () {
      // return this.$store.state.templateData ? this.$store.state.templateData : ''
      return localStorage.getItem('templateData') ? localStorage.getItem('templateData') : ''
    },
    paramsForStepThreeDotThree () {
      if (this.dataStepThree && this.dataStepThreeDotOne) {
        return {
          role: this.role,
          fio: this.dataStepThreeDotOne.fio.replace(/%/g, ' '),
          countryShortName: this.dataStepThree.countryShortName,
          country: this.dataStepThree.country,
          // company: this.role === '2' ? (this.dataStepThree.legal ? (this.dataStepThree.legal + ' ' + this.dataStepThree.orgName) : this.dataStepThree.orgName) : this.dataStepThreeDotOne.fio.replace(/%/g, ' '),
          // company: this.dataStepThree.legal ? (this.dataStepThree.legal + ' ' + this.dataStepThree.orgName) : this.dataStepThree.orgName,
          company: this.dataStepThree.orgName,
          doc: this.dataStepThree.docValue,
          email: this.dataStepThreeDotOne.email,
          billLang: this.dataStepThreeDotTwo ? this.dataStepThreeDotTwo.billLang : '',
          certType: this.editOrderParams ? this.editOrderParams.certtype : null,
          currency: this.editOrderParams ? this.editOrderParams.currency : null
        }
      } else return null
    },
    paramsForStepFourDotThree () {
      if (this.dataStepThree && this.dataStepThreeDotOne && this.dataStepFourDotTwo) {
        return {
          role: this.role,
          order: this.editOrderParams ? this.editOrderParams.order : this.objectName,
          legal: this.dataStepThree.legal,
          docValue: this.dataStepThree.docValue,
          IdData: this.dataStepThreeDotOne.IdData,
          // orgName: this.role === '1' ? this.dataStepThree.orgName : this.dataStepThreeDotOne.fio.replace(/%/g, ' '),
          orgName: this.dataStepThree.orgName,
          fio: this.dataStepThreeDotOne.fio,
          region: this.dataStepThree.region,
          city: this.dataStepThree.city,
          phone: this.dataStepThreeDotOne.phone,
          email: this.dataStepThreeDotOne.email,
          PKCS10: this.dataStepFourDotTwo.PKCS10GOST
        }
      } else return null
    },
    countryShortName () {
      return this.dataStepThree ? this.dataStepThree.countryShortName : null
    },
    doc () {
      return this.dataStepThree ? this.dataStepThree.docValue : null
    },
    dName () {
      if (this.dataStepThree && this.dataStepThreeDotOne) {
        let legal = this.dataStepThree.legal ? this.dataStepThree.legal + ' ' : ''
        let company = this.role === '1' ? (legal + this.dataStepThree.orgName) : this.dataStepThreeDotOne.fio.replace(/%/g, ' ')
        return 'C=' + this.dataStepThree.countryShortName + ';O=' + this.dataStepThree.orgName + ';CN=' + this.dataStepThreeDotOne.fio.replace(/%/g, ' ') + ';SN=' + this.dataStepThree.docValue + ';E=' + this.dataStepThreeDotOne.email
      } else return null
    },
    dNameCorporate () {
      if (this.dataStepThreeDotOne) {
        if (this.userParams) {
          if (this.userParams.type == 4) {
            return 'C=KZ' + ';O=' + this.userParams.name + ';CN=' + this.userParams.fio.replace(/%/g, ' ') + ';SN=' + this.userParams.docnum + ';E=' + this.userParams.e_mail
          } else {
            return 'C=KZ' + ';O=' + this.dataStepThree.orgName + ';CN=' + this.userParams.fio.replace(/%/g, ' ') + ';SN=' + this.userParams.docnum + ';E=' + this.userParams.e_mail
          }
        } else return null
      } else return null
    },
    countryId () {
      let re = /\s*_\s*/
      if (this.userParams) {
        let country = this.userParams.country.split(re)
        return country[0]
      } else if (this.reissueParams) {
        let country = this.reissueParams.country.split(re)
        return country[0]
      } else if (this.editOrderParams) {
        let country = this.editOrderParams.country.split(re)
        return country[0]
      } else return null
    },
    paramsForOrder () {
      if (this.role === '4' && this.dataStepFourDotOne && this.userParams) {
        return {
          enabledDevs: '',
          password: this.dataStepFourDotOne.password,
          dnPKCS10: this.dNameCorporate.replace(/,/g, '/,/'),
          role: this.role,
          order: this.orderNumber,
          template: this.userParams.template
        }
      } else {
        if (this.dataStepThree && this.dataStepFourDotOne) {
          return {
            enabledDevs: this.dataStepThree.enabledDevs,
            password: this.dataStepFourDotOne.password,
            dnPKCS10: this.dName.replace(/,/g, '/,/'),
            role: this.role,
            order: this.editOrderParams ? this.editOrderParams.order : this.objectName,
            template: this.template ? this.template : (this.editOrderParams.template ? this.editOrderParams.template : null),
            edit: !!this.editOrder
          }
        } else return null
      }
    },
    paramsForStepFive () {
      if (this.role === '4' && this.userParams) {
        return {
          order: this.orderNumber,
          role: this.role,
          orgName: this.userParams.name,
          fio: this.userParams.fio.replace(/%/g, ' '),
          email: this.userParams.e_mail,
          phone: this.userParams.tel_number
        }
      } else {
        if (this.dataStepThree && this.dataStepThreeDotOne) {
          return {
            order: this.editOrderParams ? this.editOrderParams.order : this.objectName,
            role: this.role,
            // orgName: this.role === '1' ? this.dataStepThree.orgName : this.dataStepThreeDotOne.fio.replace(/%/g, ' '),
            orgName: this.dataStepThree.orgName,
            legal: this.dataStepThree.legal,
            fio: this.dataStepThreeDotOne.fio.replace(/%/g, ' '),
            email: this.dataStepThreeDotOne.email,
            phone: this.dataStepThreeDotOne.phone
          }
        } else return null
      }
    },
    paramsForSubmit () {
      function Translit(word){
        var answer = '';
        var converter = {
          'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
          'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
          'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
          'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
          'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
          'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
          'э': 'e',    'ю': 'yu',   'я': 'ya',   'қ': 'k',    'ө':'o',
          'ү':'u',     'ұ':'u',     'і':'i',     'ә':'a',     'ғ':'g',
          'ң':'ng',

          'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
          'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
          'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
          'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
          'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
          'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
          'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya',   'Қ': 'K',    'Ө':'O',
          'Ү':'U',     'Ұ':'U',     'І':'I',     'Ә':'A',     'Ғ':'G',
          'Ң':'NG',
        };
        //for commit

        for (var i = 0; i < word.length; ++i ) {
          if (converter[word[i]] == undefined){
            answer += word[i];
          } else {
            answer += converter[word[i]];
          }
        }

        return answer;
      }
      if (this.role === '4' && this.dataStepThreeDotOne && this.dataStepFourDotOne && this.dataStepFourDotTwo && this.userParams) {
        return {
          role: this.role,
          country: this.countryId,
          docValue: this.userParams.docnum,
          // orgName: Translit(this.userParams.name),
          orgName: this.userParams.name,
          csanID: this.userParams.scan,
          fio: this.dataStepThreeDotOne.fio,
          phone: this.dataStepThreeDotOne.phone,
          email: this.dataStepThreeDotOne.email,
          oid: this.userParams.description,
          password: this.dataStepFourDotOne.password,
          order: this.orderNumber,
          // dn: Translit(this.dNameCorporate),
          dn: this.dNameCorporate,
          serial: this.dataStepFourDotTwo.serial,
          PKCS10GOST: this.dataStepFourDotTwo.PKCS10GOST,
          PKCS10RSA: this.dataStepFourDotTwo.PKCS10RSA,
          billLang: 'ru',
          lang: this.$i18n.locale
        }
      }
      else if (this.dataStepThree && this.dataStepThreeDotOne && this.dataStepFourDotOne && this.dataStepFourDotTwo) {
        return {
          role: this.role,
          country: this.dataStepThree.countryId,
          docValue: this.dataStepThree.docValue,
          duns: this.dataStepThree.duns,
          // legal: this.dataStepThree.legal,
          legal: null,
          // orgName: this.role === '1' ? this.dataStepThree.orgName : this.dataStepThreeDotOne.fio.replace(/%/g, ' '),
          // orgName: Translit(this.dataStepThree.orgName),
          orgName: this.dataStepThree.orgName,
          csanID: this.dataStepThree.csanId,
          fileName: this.dataStepThree.fileName,
          fileBlob: this.dataStepThree.fileBlob,
          fio: this.dataStepThreeDotOne.fio,
          phone: this.dataStepThreeDotOne.phone,
          email: this.dataStepThreeDotOne.email,
          currencyId: this.dataStepThreeDotTwo ? this.dataStepThreeDotTwo.currencyId : localStorage.getItem('currencyId'),
          price: this.dataStepThreeDotTwo ? this.dataStepThreeDotTwo.price : localStorage.getItem('price'),
          billLang: this.dataStepThreeDotTwo ? this.dataStepThreeDotTwo.billLang : 'ru',
          oid: this.dataStepThreeDotTwo ? (this.dataStepThreeDotTwo.oid ? this.dataStepThreeDotTwo.oid : this.editOrderParams.certtype) : localStorage.getItem('templateId'),
          password: this.dataStepFourDotOne.password,
          order: this.editOrderParams ? this.editOrderParams.order : this.objectName,
          // dn: Translit(this.dName),
          dn: this.dName,
          serial: this.dataStepFourDotTwo.serial,
          PKCS10GOST: this.dataStepFourDotTwo.PKCS10GOST,
          PKCS10RSA: this.dataStepFourDotTwo.PKCS10RSA,
          reissue: this.reissueParams ? this.reissue : null,
          // region: Translit(this.dataStepThree.region),
          // city: Translit(this.dataStepThree.city),
          // IdData: Translit(this.dataStepThreeDotOne.IdData),
          region: this.dataStepThree.region,
          city: this.dataStepThree.city,
          IdData: this.dataStepThreeDotOne.IdData,
          scanIdUser: this.dataStepThreeDotOne.scanIdUser,
          IdFileName: this.dataStepThreeDotOne.IdFileName,
          IdFileBlob: this.dataStepThreeDotOne.IdFileBlob,
          changeOrg: this.dataStepThree.changeOrg,
          changeOrgVal: this.dataStepThree.changeOrgVal,
          editOrder: this.editOrderParams ? this.editOrder : null,
          lang: this.$i18n.locale
        }
      } else return null
    }
  },
  watch: {
    result: {
      handler: function (val) {
        if (!val) {
          if (this.stepToNext) {
            this.currentForm = this.stepToNext
            this.result = this.currentForm
          }
        }
      },
      immediate: true
    },
    dataStepThree: {
      handler: function (val) {
        console.log(val)
        if (val) {
          this.role = val.user
        }
      },
      immediate: true
    },
    certificateName: {
      handler: function (val) {
        if (val && !this.reissueParams && !this.editOrderParams) {
          this.$http.get('/query/get-user-info/', { params: { order: val } }).then((response) => {
            if (response.data) {
              this.setCertForm = response.data
              if (this.setCertForm.type === '4') {
                this.setCertForm = null
              }
              if (response.data.legal) {
                this.$store.commit('updateCheckOrgWithLegal', true)
              }
            }
          })
        }
      },
      immediate: true
    },
    reissue: {
      handler: function (val) {
        if (val) {
          this.$http.get('/query/templates/').then(response => {
            this.templates = response.data
          })
          this.$http.get('/query/get-user-info/', { params: { order: val } }).then((response) => {
            if (response.data) {
              this.reissueParams = response.data
              this.role = this.reissueParams.type

              let myTemplate = new Object()
              let resTemp = response.data.template
              for (let i = 0; i < this.templates.length; i++) {
                if (this.templates[i].template == resTemp) {
                  myTemplate = this.templates[i]
                  break
                }
              }

              let tempCurrency = response.data.currency
              let tempRPrice = 0
              if (tempCurrency == 5) {
                tempRPrice = myTemplate.recover_usd
              } else if (tempCurrency == 6) {
                tempRPrice = myTemplate.recover_rur
              } else if (tempCurrency == 7) {
                tempRPrice = myTemplate.recover_eur
              } else {
                tempRPrice = myTemplate.recover_kzt
              }

              window.localStorage.setItem('price', tempRPrice)
              window.localStorage.setItem('template', myTemplate.template_name)
              window.localStorage.setItem('templateId', myTemplate.id)
              window.localStorage.setItem('templateName', myTemplate.template_name)
              window.localStorage.setItem('templateData', myTemplate.template_eussieR)
              window.localStorage.setItem('templateValidity', this.lang === 'kk' ? myTemplate.validity_kk : (this.lang === 'ru' ? myTemplate.validity : myTemplate.validity_en))

              // if (this.reissueParams.type === '4') {
              //   this.currentForm = 'FormStepThreeDotZero'
              // }
            }
          })
        }
      },
      immediate: true
    },
    editOrder: {
      handler: function (val) {
        if (val) {
          this.currentForm = 'FormStepOne'
          this.$http.get('/query/get-user-info/', { params: { order: val } }).then((response) => {
            if (response.data) {
              this.editOrderParams = response.data
              this.role = this.editOrderParams.type
            }
          })
        }
      },
      immediate: true
    },
    objectName: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/new-order/').then(response => {
            this.objectName = 'G' + response.data.name
          })
        }
      },
      immediate: true
    },
    orderNumber: { // для кк
      handler: function (val) {
        if (val) {
          this.$http.get('/query/get-user-info/', { params: { order: val, pass: this.orderPassword } }).then(response => {
            if (response.data) {
              this.userParams = response.data
              this.role = this.userParams.type
            }
          })
        }
      },
      immediate: true
    },
    currentForm: {
      handler: function setsaves(val) {
        if (val === 'lastForm') {
          this.currentForm = 'FormStepLoading'
          console.log('Params=')
          console.log(this.paramsForSubmit);

          var getUrl = window.location;
          var baseUrl = getUrl .protocol + "//" + getUrl.host;
          try {
            this.$http.post('/certificate/save', this.paramsForSubmit).then(response => {
              console.log("res1=");
              console.log(response.data);
              if (response.data) {
                this.status = response.data.status
                this.page = response.data.page
                if (this.role === '1' || this.role === '2') {
                  if (this.page === '1' || this.page === '2' || this.page === '3') {
                    this.$http.post('/certificate/doc/', {
                      order: this.objectName,
                      fileName: this.dataStepFourDotThree.AppFileName,
                      fileBlob: this.dataStepFourDotThree.AppFileBlob,
                      docApp: 'docApp'
                    }).then(response => {
                      if (response.data) {
                        this.currentForm = 'FormStepFive'
                      }
                    })
                  } else {
                    this.currentForm = 'FormStepFive'
                  }
                } else {
                  this.currentForm = 'FormStepFive'
                }
              } else {
                this.currentForm = null
              }
            })
          } catch (res){
            console.log('Again');
            setsaves();
          }
        }
      }
    }
  },
  mounted () {
    this.startSocket()
  }
}
</script>
