<template>
  <div>

    <steps :passive-steps="[4, 5, 6, 7]" :active-step="3" :finished-steps="[1, 2]"/>

    <!-- Модальное окно при смене названия компании -->
    <div class="modal is-active" v-if="modalActive">
      <div class="modal-background" v-on:click="openModal"></div>
      <div class="modal-card">
        <header class="modal-card-head"><p class="modal-card-title">{{ $t('Do you want to change the organization name?') }}</p></header>
        <section class="modal-card-body">
          <p>{{changeOrgText}}</p>
        </section>
        <footer class="modal-card-foot">
          <a class="button is-primary" v-on:click="changeOrgModal">{{ $t('Continue') }}</a>
          <a class="button" v-on:click="changeOrgModalCancel">{{ $t('Cancel') }}</a>
        </footer>
      </div>
    </div>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Step 3. Order form') }}</h1>
        <h2 class="title is-4">{{ $t('Information about the company') }}</h2>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-6">
              <div class="field">
                <label class="label">{{ $t('User Type') }}*</label>
                <div class="select is-fullwidth">
                  <select name="role"  v-model="selectedUserId" :disabled="isDisabled">
                    <option v-for="(userType, index) in roles" :key="index" :value="userType.id">
                      <template v-if="lang == 'ru'">{{userType.name}}</template>
                      <template v-if="lang == 'en'">{{userType.name_en}}</template>
                      <template v-if="lang == 'kk'">{{userType.name_kk}}</template>
                    </option>
                  </select>
                </div>
              </div>
              <div style="height:1rem;"></div>
              <div class="field">
                <label class="label" v-if="selectedUserId !== '3'">{{ $t('Select the country') }}*</label>
                <label class="label" v-if="selectedUserId === '3'">{{ $t('Select the country of your citizenship') }}*</label>
                <div class="select is-fullwidth">
                  <select name="country" v-model="selectedCountryId" :disabled="isDisabled">
                    <option v-for="(country, index) in countries" :key="index" :value="country.id">{{country.name}}</option>
                  </select>
                </div>
              </div>
              <div style="height:1rem;"></div>
              <div class="field">
                <label class="label">{{ $t('Region') }}</label>
                <div class="control">
                  <input v-model="region" name="region" v-validate="'regionRules'" class="input" type="text" :disabled="isDisabled">
                  <p v-if="errors.has('region')" class="alert-error ">{{errors.first('region')}}</p>
                </div>
              </div>
              <div style="height:1rem;"></div>
              <div class="field">
                <label class="label">{{ $t('City') }}*</label>
                <div class="control">
                  <input v-model.trim="city" name="city"  v-validate="'required|cityRules'" type="text" class="input" :class="{'has-error': errors.has('city')}" :disabled="isDisabled">
                  <p v-if="errors.has('city')" class="alert-error ">{{errors.first('city')}}</p>
                </div>
              </div>
              <div style="height:1rem;"></div>
              <div class="field">
                <label class="label" v-if="selectedUserId === '1'">{{ $t('Identification number or VAT payer number (VATIN/TAXIN)') }}*</label>
                <label class="label" v-if="selectedUserId == '2'">{{ $t('Taxpayer Identification Number (TIN)') }}*</label>
                <label class="label" v-if="selectedUserId == '3'">{{ $t('Passport number') }}*</label>
                <div class="control">
                  <input v-model="docValue" @blur="fetchDocValue" v-bind:name="selectedUserId === '3' ? $t('Passport number') : $t('Identification Number')" v-validate="'required|min:3|max:45|docValue'" type="text" class="input" :class="{'has-error': errors.has('Identification Number')}" :placeholder="selectedDocId" :disabled="isDisabled">
                  <p v-if="errors.has('Passport number')" class="alert-error ">{{errors.first('Passport number')}}</p>
                  <p v-if="errors.has('Identification Number')" class="alert-error ">{{errors.first('Identification Number')}}</p>
                </div>
                <p class="help" v-if="selectedUserId === '1'"><a v-on:click="openMoreDocValue">{{moreDocValueText}}...</a></p>
                <div v-if="moreDocValue && selectedUserId === '1'">
                  <p class="help">{{ $t('VAT number (VATIN/TAXIN) is an identifier that is assigned to a company when it is registered as a legal entity') }}.</p>
                  <p class="help">{{ $t('If your company does not have the identification number') }}.</p>
                </div>
              </div>
              <div style="height:1rem;"></div>
              <div class="field">
                <!--                  <label class="label" v-if="selectedUserId === '1'">{{ $t('Scan-copy of the Certificate of Registration') }}*</label>-->
                <label class="label" v-if="selectedUserId === '1'">{{ $t('Certificate of registration of an organization with the tax authority in your country, confirming the assignment of the identification number or VAT number (VATIN/TAXIN)') }}*</label>
                <label class="label" v-if="selectedUserId === '2'">{{ $t('Scan-copy of the Certificate of Tax Registration') }}*</label>
                <label class="label" v-if="selectedUserId === '3'">{{ $t('Scan-copy of your passport') }}*</label>
                <div class="file has-name is-primary">
                  <label class="file-label">
                    <input name="scan" id="documentScan" v-validate="!scanId ? (!inptFileName ? 'required|fileSize' : '') : (!inptFileName ? 'fileSize' : '')" accept=".jpg,.png,.pdf,.tif" type="file" @change="processFile" :disabled="isDisabled" class="file-input" :class="{'has-error': errors.has('scan')}">
                    <input name="fileName" type="hidden" :value="{inptFileName}"/>
                    <input name="fileBlob" type="hidden" :value="{inptFileBlob}"/>
                    <span class="file-cta">
                        <span class="file-icon"><img src="@/assets/icons/attach.svg"></span>
                        <span class="file-label">{{ $t('Attach a document') }}</span>
                      </span>
                    <span class="file-name" :class="{'has-text-danger': isTextDanger, 'has-text-primary': isTextPrimary}">{{fileText}}</span>
                  </label>
                  <p class="doc" v-if="scanId"><a v-on:click="getDoc()">{{ $t('Document...') }}</a></p>
                </div>
                <p v-if="errors.has('scan')" class="alert-error ">{{errors.first('scan')}}</p>
                <p class="help" v-if="selectedUserId === '1'"><a v-on:click="openMoreScan">{{moreScanText}}...</a></p>
                <p class="help" v-if="selectedUserId === '1' && selectedCountryShortName === 'CN'" v-html="$t('For China')"></p>
                <p class="help" v-if="selectedUserId === '2'"><a v-on:click="openMoreScan">{{moreScanText}}...</a></p>
                <div v-if="moreScan">
                  <p class="help">{{ $t('The attached documents should be valid at the moment of placing the order') }}.</p>
                  <!--                    <p class="help" v-if="selectedUserId === '1'">{{ $t('If your company does not have an official name') }}.</p>-->
                  <p class="help" v-if="selectedUserId === '1'">{{ $t('If your company does not have an official name in Russian or in English, then a notarized translation of the Certificate of registration of the company with the tax authority in Russian or in English is required') }}.</p>
                  <p class="help" v-if="selectedUserId === '2'">{{ $t('Attach the scan copy of the Certificate of registration of the individual entrepreneur issued by the Tax Authority') }}.</p>
                  <p class="help" v-if="selectedUserId === '3'">{{ $t('Attach the scan copy of your passport') }}.</p>
                </div>
              </div>
              <div style="height:1rem;"></div>
              <div class="field" v-if="selectedUserId === '1'">
                <label class="label">{{ $t('DUNS') }}</label>
                <div class="control">
                  <input v-model="duns" name="duns" v-validate="'dunsRules'" :class="{'has-error': errors.has('duns')}" class="input" type="text" v-bind:placeholder="$t('9 characters')" :disabled="isDisabled">
                  <p v-if="errors.has('duns')" class="alert-error ">{{errors.first('duns')}}</p>
                </div>
                <div style="height: 1rem;"></div>
              </div>
              <!--                <div class="field" v-if="(selectedCountryShortName === 'RU' || selectedCountryShortName === 'BY' || selectedCountryShortName === 'UA') && (selectedUserId === '1')">-->
              <!--                  <label class="label">{{ $t('Legal form of the company') }}*</label>-->
              <!--                  <div class="select is-fullwidth">-->
              <!--                    <select v-model="selectedLegalId" name="legal" :class="{'has-error': errors.has('legal')}" :disabled="isDisabled">-->
              <!--                      <option value="">{{ $t('Not selected') }}</option>-->
              <!--                      <option v-for="(legal, index) in legals" :key="index" :value="legal.id">{{lang === 'ru' ? legal.name : legal.name_en}}</option>-->
              <!--                      <option value="selfVal">{{ $t('Other') }}</option>-->
              <!--                    </select>-->
              <!--                    <p v-if="errors.has('legal')" class="alert-error ">{{errors.first('legal')}}</p>-->
              <!--                  </div>-->
              <!--                  <div class="control" v-if="selectedLegalId === 'selfVal'">-->
              <!--                    <input v-model.trim="legalSelfVal" name="legalSelfVal" v-validate="'required'" :class="{'has-error': errors.has('legalSelfVal')}" class="input" type="text" v-bind:placeholder="$t('Legal form of the company')" :disabled="isDisabled">-->
              <!--                    <p v-if="errors.has('legalSelfVal')" class="alert-error ">{{errors.first('legalSelfVal')}}</p>-->
              <!--                  </div>-->
              <!--                  <div style="height:1rem;"></div>-->
              <!--                </div>-->
              <div class="field" v-if="selectedUserId === '1' || selectedUserId === '2'">
                <label class="label" v-if="selectedUserId === '1'">{{ $t('Company name form') }}*</label>
                <label class="label" v-if="selectedUserId === '2'">{{ $t('Individual entrepreneur name') }}*</label>
                <div class="control">
                  <input v-model.trim="orgName" name="orgName"  v-validate="'required|min:1|max:192|orgName'" type="text" class="input" :class="{'has-error': errors.has('orgName')}" v-bind:placeholder="$t('from 1 to 192 characters')" :disabled="isDisabled">
                  <p v-if="errors.has('orgName')" class="alert-error ">{{errors.first('orgName')}}</p>
                </div>
                <p class="help"><a v-on:click="openMoreOrgName">{{moreOrgNameText}}...</a></p>
                <div v-if="moreOrgName">
                  <p class="help">{{ $t('The entered name of the company have to be full and match') }}.</p>
                  <p class="help">{{ $t('If your company does not have an official name in Russian / English language, you should enter the name') }}.</p>
                </div>
                <div style="height: 1rem;"></div>
              </div>

              <div class="buttons">
                <input class="button is-large" type="button" v-on:click="GoToStepTwo" v-bind:value="$t('← Back')">
                <input class="button right is-primary is-large" type="button" v-on:click="GoToStepTreeDotOne" v-bind:value="$t('Next →')">
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import steps from '../steps/Steps'
import fileSaver from 'file-saver'
import { Base64 } from 'js-base64'
export default {
  name: 'OrderStepThree',
  components: {
    steps
  },
  props: [
    'currentForm',
    'dataStepThree',
    'countryCode',
    'reissueParams',
    'editOrderParams',
    'setCertForm'
  ],
  data () {
    return {
      roles: null,
      countries: null,
      legals: null,
      selectedUserId: '1',
      selectedCountryId: null,
      selectedLegalId: '',
      isTextDanger: true,
      isTextPrimary: false,
      fileSize: null,
      inptFileName: null,
      inptFileBlob: null,
      docValue: null,
      duns: null,
      legalSelfVal: null,
      legalName: null,
      orgName: null,
      scanId: null,
      isDisabled: false,
      scanName: null,
      region: null,
      city: null,
      changeOrg: null,
      changeOrgText: null,
      modalActive: false,
      moreDocValue: false,
      moreScan: false,
      moreOrgName: false,
      moreCountry: false,
      fileText: this.$i18n.t('No document selected').toString()
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    currentCountry () {
      return this.countries ? this.countries.find(item => item.id === this.selectedCountryId) : null
    },
    currentLegal () {
      return this.legals ? this.legals.find(item => item.id === this.selectedLegalId) : null
    },
    selectedCountryName () {
      return this.currentCountry ? this.currentCountry.name : null
    },
    selectedCountryShortName () {
      return this.currentCountry ? this.currentCountry.shortname : null
    },
    selectedEnabledDevs () {
      return this.currentCountry ? this.currentCountry.disabled : ''
    },
    selectedDocId () {
      if (this.selectedUserId === '1') {
        return this.currentCountry ? this.currentCountry.longname : null
      }
      return null
    },
    selectedLegal () {
      return this.currentLegal ? this.currentLegal.name : ''
    },
    legalForOgr () {
      return this.selectedLegalId === 'selfVal' ? this.legalSelfVal : this.selectedLegal
    },
    // fileText: {
    //   get () {
    //     return this.$i18n.t('No document selected').toString()
    //   },
    //   set (newValue) {
    //     return newValue
    //   }
    // },
    moreDocValueText () {
      return this.moreDocValue ? this.$i18n.t('Hide') : this.$i18n.t('Read more')
    },
    moreScanText () {
      return this.moreScan ? this.$i18n.t('Hide') : this.$i18n.t('Read more')
    },
    moreOrgNameText () {
      return this.moreOrgName ? this.$i18n.t('Hide') : this.$i18n.t('Read more')
    },
    moreCountryText () {
      return this.moreCountry ? this.$i18n.t('Hide') : this.$i18n.t('Read more')
    },
    template () {
      return localStorage.getItem('template') ? localStorage.getItem('template') : ''
    },
    checkWithLegal () {
      return this.$store.state.checkOrgWithLegal
    }
  },
  methods: {
    getDoc () {
      this.$http.post('/certificate/get-scan', { scan: this.scanId }, { responseType: 'blob' }).then(response => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          fileSaver.saveAs(blob, this.scanName)
        }
      })
    },
    docScanValidate () {
      let _validFileExtensions = ['.jpg', '.png', '.pdf', '.tif']
      let myInput = document.getElementById('documentScan')
      if (myInput.type == 'file') {
        let sFileName = myInput.value
        if (sFileName.length > 0) {
          let blnValid = false
          for (let j = 0; j < _validFileExtensions.length; j++) {
            let sCurExtension = _validFileExtensions[j]
            if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true
              break
            }
          }
          if (!blnValid) {
            this.scanId = null
            alert(this.$i18n.t('Upload the file in JPG, PNG, TIF, PDF format (file size shall not exceed 2 MB)'))
            myInput.value = null
            return false
          }
        }
      }
      return true
    },
    processFile (event) {
      this.docScanValidate()
      if (event) {
        const file = event.target.files[0]
        this.fileSize = file ? file.size : null
        if (file && file.size <= 2097152) {
          this.fileText = file.name
          this.isTextDanger = false
          this.isTextPrimary = true
          const reader = new FileReader()
          reader.onload = e => this.inptFileBlob = (e.target.result).split(',')[1]
          reader.readAsDataURL(file)
          this.inptFileName = file.name
        } else {
          this.fileText = this.$i18n.t('No document selected').toString()
          this.inptFileName = null
          this.inptFileBlob = null
          this.isTextDanger = true
          this.isTextPrimary = false
        }
      }
    },
    currentLegalName () {
      // const legal = this.legals ? this.legals.find(item => item.name === this.legalName) : null
      const legal = null
      this.selectedLegalId = legal ? legal.id : 'selfVal'
      this.legalSelfVal = (this.selectedLegalId === 'selfVal') ? this.legalName : null
    },
    fetchDocValue () {
      this.$http.get('/query/get-organization/', { params: { doc: this.docValue } }).then(response => {
        if (response.data) {
          if (response.data.legal) {
            this.orgName = response.data.legal + ' ' + response.data.orgname
            this.changeOrg = 0
            this.modalActive = false

            this.$store.commit('updateCheckOrgWithLegal', true)
          } else {
            this.orgName = response.data.orgname
            this.$store.commit('updateCheckOrgWithLegal', false)
          }
          // this.legalName = (this.selectedCountryShortName === 'RU' || this.selectedCountryShortName === 'BY' || this.selectedCountryShortName === 'UA') ? response.data.legal : ''
          // if (this.legalName) {
          //   this.currentLegalName()
          // }
        }
      })
    },
    GoToStepTwo () {
      this.$emit('update:currentForm', 'FormStepTwo')
    },
    Update () {
      const legal = this.selectedLegalId ? (this.selectedLegalId === 'selfVal' ? this.legalSelfVal : this.selectedLegal) : ''
      const company = this.orgName ? this.orgName.replace(/«/g, '"').replace(/»/g, '"') : ''
      this.$emit('update:dataStepThree', {
        user: this.selectedUserId,
        country: this.selectedCountryName,
        countryShortName: this.selectedCountryShortName,
        countryId: this.selectedCountryId,
        enabledDevs: this.selectedEnabledDevs,
        docValue: this.docValue,
        fileName: this.inptFileName,
        fileBlob: this.inptFileBlob,
        duns: this.duns,
        legal: legal,
        orgName: company,
        csanId: this.scanId,
        region: this.region,
        city: this.city,
        changeOrg: this.changeOrg
      })
    },
    GoToStepTreeDotOne () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.selectedUserId === '1') {
            const legal = this.selectedLegalId === 'selfVal' ? this.legalSelfVal : this.selectedLegal
            const company = this.orgName.replace(/«/g, '"').replace(/»/g, '"')

            let tempCompany = company

            if (this.checkWithLegal == true) {
              tempCompany = company.replace(legal, '').replace(' ', '')
            }

            this.$http.post('/certificate/check-org', { doc: this.docValue, orgName: tempCompany, legal: legal }).then(response => {
              if (response.data) {
                this.Update()
                this.$emit('update:currentForm', 'FormStepThreeDotOne')
              } else {
                this.changeOrg = '1'
                this.changeOrgText = this.$i18n.t('You are trying to change the organization name which already has orders! Continue?').toString()
                this.openModal()
              }
            })
          } else {
            this.Update()
            this.$emit('update:currentForm', 'FormStepThreeDotOne')
          }
        }
      }).catch(error => console.log(error.message))
    },
    changeOrgModal () {
      this.Update()
      this.$emit('update:currentForm', 'FormStepThreeDotOne')
    },
    openModal () {
      this.modalActive = true
    },
    changeOrgModalCancel () {
      this.modalActive = false
      this.changeOrg = null
      this.changeOrgText = null
    },
    openMoreDocValue () {
      this.moreDocValue = !this.moreDocValue
    },
    openMoreScan () {
      this.moreScan = !this.moreScan
    },
    openMoreOrgName () {
      this.moreOrgName = !this.moreOrgName
    },
    openMoreCountry () {
      this.moreCountry = !this.moreCountry
    }
  },
  watch: {
    // city: {
    //   handler: function (val) {
    //     if (val) {
    //       this.city = val.charAt(0).toUpperCase() + val.substr(1).toLowerCase()
    //     }
    //   }
    // },
    dataStepThree: {
      handler: function (val) {
        if (val) {
          this.selectedUserId = val.user
          this.duns = val.duns
          this.docValue = val.docValue
          this.orgName = val.orgName
          this.inptFileName = val.fileName
          this.inptFileBlob = val.fileBlob
          this.legalName = val.legal
          this.region = val.region
          this.city = val.city
        }
      },
      immediate: true
    },
    inptFileName: {
      handler: function (val) {
        if (val) {
          this.fileText = this.inptFileName
          this.isTextDanger = false
          this.isTextPrimary = true
        }
      },
      immediate: true
    },
    reissueParams: {
      handler: function (val) {
        if (val) {
          this.$http.post('/certificate/get-scan-name', { scan: val.scan }).then(response => {
            console.log('scanName=' + response.data)
            if (response.data) {
              this.scanName = response.data
              this.fileText = this.scanName
              this.isTextDanger = false
              this.isTextPrimary = true
            }
          })
          this.selectedUserId = val.type
          this.docValue = val.docnum
          this.orgName = val.legal ? val.legal + ' ' + val.name : val.name
          this.scanId = val.scan
          this.legalName = val.legal
          this.duns = val.duns
          this.region = val.region
          this.city = val.city
          this.isDisabled = true
          this.currentLegalName()
        }
      },
      immediate: true
    },
    editOrderParams: {
      handler: function (val) {
        if (val) {
          this.$http.post('/certificate/get-scan-name', { scan: val.scan }).then(response => {
            console.log('scanName=' + response.data)
            if (response.data) {
              this.scanName = response.data
              this.fileText = this.scanName
              this.isTextDanger = false
              this.isTextPrimary = true
            }
          })
          this.currentLegalName()
          if (!this.dataStepThree) {
            this.selectedUserId = val.type
            this.docValue = val.docnum
            this.orgName = val.name
            this.scanId = val.scan
            this.legalName = val.legal
            this.duns = val.duns
            this.region = val.region
            this.city = val.city
          }
        }
      },
      immediate: true
    },
    setCertForm: {
      handler: function (val) {
        if (val) {
          this.$http.post('/certificate/get-scan-name', { scan: val.scan }).then(response => {
            if (response.data) {
              this.scanName = response.data
              this.fileText = this.scanName
              this.isTextDanger = false
              this.isTextPrimary = true
            }
          })
          this.selectedUserId = val.type
          this.docValue = val.docnum
          this.orgName = val.legal ? val.legal + ' ' + val.name : val.name
          this.scanId = val.scan
          this.legalName = val.legal
          this.duns = val.duns
          this.region = val.region
          this.city = val.city
        }
      },
      immediate: true
    },
    roles: {
      handler: function (val) {
        if (!val) {
          if (this.template === 'Test') {
            this.$http.get('/query/roles-test/').then(response => {
              this.roles = response.data
            })
          } else {
            this.$http.get('/query/roles/').then(response => {
              this.roles = response.data
            })
          }
        }
      },
      immediate: true
    },
    countries: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/countries/').then(response => {
            this.countries = response.data
            let re = /\s*_\s*/
            if (this.setCertForm) {
              let country = this.setCertForm.country.split(re)
              this.selectedCountryId = country[0]
            } else if (this.dataStepThree) {
              this.selectedCountryId = this.dataStepThree.countryId
            } else if (this.editOrderParams) {
              let country = this.editOrderParams.country.split(re)
              this.selectedCountryId = country[0]
            } else if (this.reissueParams) {
              let country = this.reissueParams.country.split(re)
              this.selectedCountryId = country[0]
            } else {
              this.$http.get('/query/get-country/').then(response => {
                if (response.data) {
                  console.log(response.data)
                  const country = this.countries ? this.countries.find(item => item.name === response.data) : null
                  this.selectedCountryId = country.id
                } else {
                  this.selectedCountryId = '2'
                }
              })
            }
          })
        }
      },
      immediate: true
    },
    legals: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/legals/').then(response => {
            this.legals = response.data
            if (this.legalName) {
              this.currentLegalName()
            }
          })
        }
      },
      immediate: true
    }
  },
  created () {
    this.$validator.extend('docValue', {
      validate: value => {
        let strongRegex = new RegExp(/^[a-zA-Z0-9./_-]+$/i)
        return strongRegex.test(value)
      }
    })
    this.$validator.extend('orgName', {
      validate: value => {
        let strongRegex = new RegExp(/^[a-zA-ZА-Яа-яА-яЁё0-9+,.()«»<>=;:”_& "-]+$/i)
        return strongRegex.test(value)
      }
    })
    this.$validator.extend('dunsRules', {
      validate: value => {
        return value.length === 9
      }
    })
    this.$validator.extend('regionRules', {
      validate: value => {
        let strongRegex = new RegExp(/^[a-zA-ZА-Яа-яА-яЁё,`'’ -]+$/i)
        return strongRegex.test(value)
      }
    })
    this.$validator.extend('cityRules', {
      validate: value => {
        let strongRegex = new RegExp(/^[a-zA-ZА-Яа-яА-яЁё,`'’ -]+$/i)
        return strongRegex.test(value)
      }
    })
    this.$validator.extend('fileSize', {
      validate: value => {
        if (this.fileSize > 2097152) {
          return false
        } else return true
      }
    })
  }
}
</script>

<style scoped lang="scss">
  .file-name {
    max-width: 700px !important;
  }
  .right {
    margin-left: 20px;
  }
  .has-user-border {
    border: 1px solid #339968;
  }
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
  .doc {
    margin-left: 100px;
  }
  .textareaOrg {
    min-width: 45%;
    width: auto;
    height: 80px;
  }
</style>
