<template>
  <div>

    <steps :passive-steps="[5, 6, 7]" :active-step="4" :finished-steps="[1, 2, 3]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Keys are being formed') }}</h1>
        <p class="has-text-danger">{{ $t('Please, do not remove the security token before the end of operation') }}!</p>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-6">
              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(1), 'has-text-danger': isTextDanger(1), 'has-text-primary': isTextPrimary(1), 'is-marginless': isTextMarginless(1)}">
                    {{tryTokenText}}
                  </p>
                  <TokenHelpText v-if="tokenPage" param="step"/>
                  <p class="help help-mod" v-html="tryTokenHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="tryToken" width="32">
                </div>
              </div>

              <div class="media" v-if="edit">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(2), 'has-text-danger': isTextDanger(2), 'has-text-primary': isTextPrimary(2), 'is-marginless': isTextMarginless(2)}">
                    {{checkKeysText}}
                  </p>
                  <p class="help help-mod"></p>
                </div>
                <div class="media-right">
                  <img :src="checkKeys" width="32">
                </div>
              </div>

              <div class="media" v-if="!edit">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(2), 'has-text-danger': isTextDanger(2), 'has-text-primary': isTextPrimary(2), 'is-marginless': isTextMarginless(2)}">
                    {{generateKeyPairGOSTText}}
                  </p>
                  <p class="help help-mod"></p>
                </div>
                <div class="media-right">
                  <img :src="generateKeyPairGOST" width="32">
                </div>
              </div>

              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(3), 'has-text-danger': isTextDanger(3), 'has-text-primary': isTextPrimary(3), 'is-marginless': isTextMarginless(3)}">
                    {{createPKCS10GOSTText}}
                  </p>
                  <p class="help help-mod" v-html="createPKCS10GOSTHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="createPKCS10GOST" width="32">
                </div>
              </div>

              <div class="media" v-if="!edit">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(4), 'has-text-danger': isTextDanger(4), 'has-text-primary': isTextPrimary(4), 'is-marginless': isTextMarginless(4)}">
                    {{generateKeyPairRSAText}}
                  </p>
                  <p class="help help-mod" v-html="generateKeyPairRSAHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="generateKeyPairRSA" width="32">
                </div>
              </div>

              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(5), 'has-text-danger': isTextDanger(5), 'has-text-primary': isTextPrimary(5), 'is-marginless': isTextMarginless(5)}">
                    {{createPKCS10RSAText}}
                  </p>
                  <p class="help help-mod" v-html="createPKCS10RSAHelpText"></p>
                </div>
                <div class="media-right">
                  <img :src="createPKCS10RSA" width="32">
                </div>
              </div>

              <div v-if="catchErrorRefresh">
                <div style="height: 2em;"></div>
                <input class="button is-primary is-medium" type="button" v-on:click="TryAgainRefresh" v-bind:value="$t('← Edit')">
              </div>
              <div v-if="catchError">
                <div style="height: 2em;"></div>
                <input class="button is-primary is-medium" type="button" v-on:click="TryAgain" v-bind:value="$t('Send the request →')">
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
<!--    <h1><pre>{{devices}}</pre></h1>-->
<!--    <h1><pre>{{enabledDevs}}</pre></h1>-->
  </div>
</template>

<script>
import steps from '../steps/Steps'
import keyProblem from '../components/KeyProblem'
import TokenHelpText from '../steps/TokenHelpPage'
export default {
  name: 'OrderStepFourDotTwo',
  components: {
    steps,
    keyProblem,
    TokenHelpText
  },
  props: [
    'currentForm',
    'dataStepFourDotTwo',
    'paramsForOrder'
  ],
  data () {
    return {
      textGreyLight: [1, 2, 3, 4, 5],
      textDanger: [],
      textPrimary: [],
      textMarginless: [],
      allChecked: false,
      tryToken: require('../assets/icons/check_silver.svg'),
      generateKeyPairGOST: require('../assets/icons/check_silver.svg'),
      createPKCS10GOST: require('../assets/icons/check_silver.svg'),
      generateKeyPairRSA: require('../assets/icons/check_silver.svg'),
      createPKCS10RSA: require('../assets/icons/check_silver.svg'),
      tryTokenText: this.$i18n.t('Security token preparation').toString(),
      generateKeyPairGOSTText: this.$i18n.t('Signature key generation').toString(),
      createPKCS10GOSTText: this.$i18n.t('PKCS#10 request generation').toString(),
      generateKeyPairRSAText: this.$i18n.t('Encryption key generation').toString(),
      createPKCS10RSAText: this.$i18n.t('PKCS#10 request generation').toString(),
      tryTokenHelpText: null,
      createPKCS10GOSTHelpText: null,
      generateKeyPairRSAHelpText: null,
      createPKCS10RSAHelpText: null,
      devices: null,
      reader: null,
      serialNumber: null,
      objectsGOST: null,
      objectsRSA: null,
      memoryGOST: null,
      memoryRSA: null,
      keyPairGOSTValue: null,
      PKCS10GOSTValue: null,
      keyPairRSAValue: null,
      PKCS10RSAValue: null,
      catchError: false,
      catchErrorRefresh: false,
      tokenPage: null,
      checkKeysText: this.$i18n.t('Checking the existence of EDS keys').toString(),
      checkKeys: require('../assets/icons/check_silver.svg')
    }
  },
  computed: {
    enabledDevs () {
      return this.paramsForOrder ? this.paramsForOrder.enabledDevs : null
    },
    template () {
      return this.paramsForOrder ? this.paramsForOrder.template : null
    },
    password () {
      return this.paramsForOrder ? this.paramsForOrder.password : null
    },
    role () {
      return this.paramsForOrder ? this.paramsForOrder.role : null
    },
    order () {
      return this.paramsForOrder ? this.paramsForOrder.order : null
    },
    dnPKCS10 () {
      return this.paramsForOrder ? this.paramsForOrder.dnPKCS10 : null
    },
    edit () {
      return this.paramsForOrder ? this.paramsForOrder.edit : null
    },
    templateType () {
      return localStorage.getItem('template')
    }
  },
  methods: {
    isTextGreyLight (id) {
      return this.textGreyLight.find(text => text === id)
    },
    isTextDanger (id) {
      return this.textDanger.find(text => text === id)
    },
    isTextPrimary (id) {
      return this.textPrimary.find(text => text === id)
    },
    isTextMarginless (id) {
      return this.textMarginless.find(text => text === id)
    },
    checkDevicePromise () {
      return new Promise((resolve, reject) => {
        this.devices = this.devices.devices
        this.reader = this.devices[0].reader
        console.log('reader=' + this.reader)
        console.log(this.devices[0])
        console.log(this.role);
        let error = null
        let errorReject = null
        if (this.devices.length > 2) {
          error = this.$i18n.t('Found more than one security token').toString()
          this.tryTokenHelpText = this.$i18n.t('Make sure, that only one security token is connected to the PC and try the operation again').toString()
          errorReject = 'device = ' + this.devices.length
        }
        if (this.devices[0].name === 'KzToken_not_supported' || this.reader.indexOf('Rutoken') >= 0) {
          error = this.$i18n.t('The security token is not supported').toString()
          errorReject = 'device = ' + this.reader
        }
        if (this.enabledDevs) {
          console.log('enabledDevs=' + this.enabledDevs)
          for (let i = 0; i < this.devices.length; i++) {
            console.log(this.devices[i].name);
            if (this.devices[i].algorithm.indexOf('GOST') >= 0) {
              if (this.enabledDevs.indexOf(',')) {
                let re = /\s*,\s*/
                let res = this.enabledDevs.split(re)
                for (let j = 0; j < res.length; j++) {
                  //                  if (this.devices[i].name.indexOf(res[j]) >= 0) {
                  if (this.devices[i].name === res[j]) {
                    error = this.$i18n.t('Working with this security token is not supported').toString() // запрещено в кабинете
                    errorReject = 'device = ' + this.enabledDevs
                  }
                }
              } else {
                if (this.devices[i].name === this.enabledDevs) {
                  //                if (this.devices[i].name.indexOf(this.enabledDevs) >= 0) {
                  error = this.$i18n.t('Working with this security token is not supported').toString() // запрещено в кабинете
                  errorReject = 'device = ' + this.enabledDevs
                }
              }
            }
          }
        }
        if (error) {
          if (this.enabledDevs) {
            this.catchErrorRefresh = true
          } else {
            this.catchError = true
          }
          this.tryTokenText = error
          this.textDanger = [1]
          this.textMarginless = [1]
          this.tryToken = require('../assets/icons/error.svg')
          reject(errorReject)
        } else {
          console.log('CheckPromise DONE!');
          resolve(this.tryToken)
        }
      })
    },

    checkKeysPromise () {
      return new Promise((resolve, reject) => {
        let keyGOST = null
        let keyRSA = null
        if (this.objectsGOST) {
          for (let i = 0; i < this.objectsGOST.length; i++) {
            if ((this.objectsGOST[i].name).indexOf('G') === 0) { // наш ключ
              if (this.objectsGOST[i].name === this.order) {
                console.log('keyGOST = true')
                keyGOST = true
              }
            }
          }
        }
        if (this.objectsRSA) {
          for (let i = 0; i < this.objectsRSA.length; i++) {
            if ((this.objectsRSA[i].name).indexOf('G') === 0) { // наш ключ
              if (this.objectsRSA[i].name === this.order) {
                console.log('keyRSA = true')
                keyRSA = true
              }
            }
          }
        }
        if (!keyGOST || !keyRSA) {
          this.catchError = true
          this.checkKeysText = this.$i18n.t('No keys found on security token').toString()
          this.textDanger = [2]
          this.textMarginless = [2]
          this.checkKeys = require('../assets/icons/error.svg')
          reject(this.$i18n.t('No keys found on security token').toString())
        } else {
          resolve(this.checkKeys)
        }
      })
    },
    async deleteKey () {
      if (this.objectsGOST) {
        for (let i = 0; i < this.objectsGOST.length; i++) {
          if ((this.objectsGOST[i].name).indexOf('G') === 0) { // наш ключ
            if (this.role === '4') { // удаление только для кк
              if (this.objectsGOST[i].name === this.order) {
                await this.cryptoSocket.DeleteKeyPair({ id: 3, reader: this.reader, pass: this.password, objectName: this.objectsGOST[i].name, alg: 'GOST' })
              }
            }
            if (!this.objectsGOST[i].cert_b64) { // нужно удалить только объекты, у которых нет сертификатов
              await this.cryptoSocket.DeleteKeyPair({ id: 1, reader: this.reader, pass: this.password, objectName: this.objectsGOST[i].name, alg: 'GOST' })
            }
          }
        }
      }
      if (this.objectsRSA) {
        for (let i = 0; i < this.objectsRSA.length; i++) {
          if ((this.objectsRSA[i].name).indexOf('G') === 0) { // наш ключ
            if (this.role === '4') { // удаление только для кк
              if (this.objectsRSA[i].name === this.order) {
                await this.cryptoSocket.DeleteKeyPair({ id: 4, reader: this.reader, pass: this.password, objectName: this.objectsRSA[i].name, alg: 'RSA' })
              }
            }
            if (!this.objectsRSA[i].cert_b64) { // нужно удалить только объекты, у которых нет сертификатов
              await this.cryptoSocket.DeleteKeyPair({ id: 2, reader: this.reader, pass: this.password, objectName: this.objectsRSA[i].name, alg: 'RSA' })
            }
          }
        }
      }
    },
    checkMemoryPromise () {
      return new Promise((resolve, reject) => {
        let sizeGOST = '2048'
        let sizeRSA = '5120'
        let error = null
        let errorReject = null
        console.log('memGost = '+this.memoryGOST.count);
        console.log('memRSA = '+this.memoryRSA.count);

        if(this.memoryGOST.count < 9110) this.memoryGOST.count = 9110;
        if(this.memoryRSA.count < 9110) this.memoryRSA.count = 9110;

        if (parseInt(this.memoryGOST.count) < sizeGOST) {
          error = this.$i18n.t('Not enough memory on security token', [' alg=GOST']).toString()
          errorReject = 'memory GOST = ' + this.memoryGOST.count
        }
        if (parseInt(this.memoryRSA.count) < sizeRSA) {
          error = this.$i18n.t('Not enough memory on security token', [' alg=RSA']).toString()
          errorReject = 'memory RSA = ' + this.memoryRSA.count
        }
        if (error) {
          this.catchError = true
          this.tryTokenText = error
          this.textDanger = [1]
          this.textMarginless = [1]
          this.tryToken = require('../assets/icons/error.svg')
          reject(errorReject)
        } else {
          resolve(this.tryToken)
        }
      })
    },
    startStepOne () {
      this.tryToken = require('../assets/icons/loading.svg')
      this.textGreyLight = [2, 3, 4, 5]
    },
    endStepOne () {
      this.tryTokenText = this.$i18n.t('The security token is prepared').toString()
      this.textPrimary = [1]
      this.tryToken = require('../assets/icons/check_green.svg')
    },
    startStepTwo () {
      this.textGreyLight = [3, 4, 5]
      if (this.edit) {
        this.chechKeys = require('../assets/icons/loading.svg')
      } else {
        this.generateKeyPairGOST = require('../assets/icons/loading.svg')
      }
    },
    endStepTwo () {
      return new Promise(resolve => {
        const vm = this
        setTimeout(() => {
          vm.textPrimary = [1, 2]
          if (this.edit) {
            vm.checkKeysText = this.$i18n.t('The existence of EDS keys is checked').toString()
            vm.checkKeys = require('../assets/icons/check_green.svg')
          } else {
            vm.generateKeyPairGOSTText = this.$i18n.t('Signature key is generated').toString()
            vm.generateKeyPairGOST = require('../assets/icons/check_green.svg')
          }
          resolve(vm.generateKeyPairGOST)
        }, 1000)
      })
    },
    startStepThree () {
      this.createPKCS10GOST = require('../assets/icons/loading.svg')
      this.textGreyLight = [4, 5]
    },

    endStepThree () {
      return new Promise(resolve => {
        this.PKCS10GOSTValue = this.PKCS10GOSTValue.pkcs10
        const vm = this
        setTimeout(() => {
          vm.createPKCS10GOSTText = this.$i18n.t('PKCS#10 GOST request is generated').toString()
          vm.textPrimary = [1, 2, 3]
          vm.createPKCS10GOST = require('../assets/icons/check_green.svg')
          resolve(vm.createPKCS10GOSTText)
        }, 2000)
      })
    },
    startStepFour () {
      this.generateKeyPairRSA = require('../assets/icons/loading.svg')
      this.textGreyLight = [5]
    },
    endStepFour () {
      this.generateKeyPairRSAText = this.$i18n.t('Encryption key is generated').toString()
      this.textPrimary = [1, 2, 3, 4]
      this.generateKeyPairRSA = require('../assets/icons/check_green.svg')
    },
    startStepFive () {
      this.createPKCS10RSA = require('../assets/icons/loading.svg')
      this.textGreyLight = []
    },
    endStepFive () {
      return new Promise(resolve => {
          this.allChecked = true
        this.PKCS10RSAValue = this.PKCS10RSAValue.pkcs10
        const vm = this
        setTimeout(() => {
          vm.createPKCS10RSAText = this.$i18n.t('PKCS#10 RSA request is generated').toString()
          vm.textPrimary = [1, 2, 3, 4, 5]
          vm.createPKCS10RSA = require('../assets/icons/check_green.svg')
          resolve(vm.createPKCS10RSA)
        }, 2000)
      })
    },
    nextStep () {
      // if (this.role === '4' || this.templateType === 'Test' || this.edit) {
      if (this.role === '4' || this.edit) {
        this.$emit('update:currentForm', 'lastForm')
      } else {
        this.$emit('update:currentForm', 'FormStepFourDotThree')
      }
      this.$emit('update:dataStepFourDotTwo', {
        serial: this.serialNumber,
        PKCS10GOST: this.PKCS10GOSTValue,
        PKCS10RSA: this.PKCS10RSAValue
      })
    },

    async checkAll () {
      try {
        this.startStepOne()
        this.devices = await this.cryptoSocket.getDevices({ id: 3 })
        await this.checkDevicePromise()

        this.objectsGOST = await this.cryptoSocket.getObjects({ id: 1, reader: this.reader, pass: this.password, alg: 'GOST' })
        this.objectsRSA = await this.cryptoSocket.getObjects({ id: 2, reader: this.reader, pass: this.password, alg: 'RSA' })
        if (this.objectsGOST.objects) {
          this.objectsGOST = JSON.parse(this.objectsGOST.objects)
          this.objectsGOST = this.objectsGOST.objects
        }
        if (this.objectsRSA.objects) {
          this.objectsRSA = JSON.parse(this.objectsRSA.objects)
          this.objectsRSA = this.objectsRSA.objects
        }
        this.serialNumber = await this.cryptoSocket.GetSerialNumber({ id: 1, reader: this.reader, alg: 'GOST' })
        this.serialNumber = this.serialNumber.serial
        if (!this.edit) {
          this.reader.indexOf('KAZTOKEN') ? (this.memoryGOST = await this.cryptoSocket.GetFreeMemorySize({ id: 1, reader: this.reader, alg: 'GOST' })) : null
          this.reader.indexOf('KAZTOKEN') ? (this.memoryRSA = await this.cryptoSocket.GetFreeMemorySize({ id: 2, reader: this.reader, alg: 'RSA' })) : null
          await this.deleteKey()
          await this.checkMemoryPromise()
          this.endStepOne()
          this.startStepTwo()
          this.keyPairGOSTValue = await this.cryptoSocket.GenerateKeyPair({ id: 1, reader: this.reader, pass: this.password, alg: 'GOST', objectName: this.order, algType: 1 })
          await this.endStepTwo()
          this.startStepThree()
          this.PKCS10GOSTValue = await await this.cryptoSocket.CreatePKCS10({ id: 1, reader: this.reader, pass: this.password, alg: 'GOST', objectName: this.order, subjectDN: this.dnPKCS10, templDN: this.template })
          await this.endStepThree()
          this.startStepFour()
          this.keyPairRSAValue = await this.cryptoSocket.GenerateKeyPair({ id: 2, reader: this.reader, pass: this.password, alg: 'RSA', objectName: this.order, algType: 8 })
          this.endStepFour()
          this.startStepFive()
          this.PKCS10RSAValue = await await this.cryptoSocket.CreatePKCS10({ id: 2, reader: this.reader, pass: this.password, alg: 'RSA', objectName: this.order, subjectDN: this.dnPKCS10, templDN: this.template })
          await this.endStepFive()
        } else { // корректировка
          this.endStepOne()
          this.startStepTwo()
          await this.checkKeysPromise()
          this.endStepTwo()
          this.startStepThree()
          this.PKCS10GOSTValue = await await this.cryptoSocket.CreatePKCS10({ id: 1, reader: this.reader, pass: this.password, alg: 'GOST', objectName: this.order, subjectDN: this.dnPKCS10, templDN: this.template })
          await this.endStepThree()
          this.startStepFive()
          this.PKCS10RSAValue = await await this.cryptoSocket.CreatePKCS10({ id: 2, reader: this.reader, pass: this.password, alg: 'RSA', objectName: this.order, subjectDN: this.dnPKCS10, templDN: this.template })
          await this.endStepFive()
        }
        this.nextStep()
      } catch (e) {
        if (e.Function === 'getObjects'){
          this.TryAgain();
        }
        if (e.Function === 'getDevices') {
          this.catchError = true
          this.textGreyLight = [2, 3, 4, 5]
          this.textDanger = [1]
          this.textMarginless = [1]
          this.tryToken = require('../assets/icons/error.svg')
          this.tryTokenText = this.$i18n.t('Security token not found or not supported').toString()
          this.tokenPage = true
        }
        if (e.Function === 'GetFreeMemorySize') {
          this.catchError = true
          this.textDanger = [1]
          this.textMarginless = [1]
          this.tryToken = require('../assets/icons/error.svg')
          this.tryTokenText = this.$i18n.t('Not enough memory on security token', ['']).toString()
        }
        if (e.Function === 'GetSerialNumber') {
          this.catchError = true
          this.textDanger = [1]
          this.textMarginless = [1]
          this.tryToken = require('../assets/icons/error.svg')
          this.tryTokenText = this.$i18n.t('Serial number generation is failed').toString()
        }
        if (e.Function === 'GenerateKeyPair' && e.id === '1') {
          this.catchError = true
          this.textDanger = [2]
          this.textMarginless = [2]
          this.generateKeyPairGOST = require('../assets/icons/error.svg')
          this.generateKeyPairGOSTText = this.$i18n.t('Signature key generation error').toString()
        }
        if (e.Function === 'GenerateKeyPair' && e.id === '2') {
          this.catchError = true
          this.textDanger = [4]
          this.textMarginless = [4]
          this.generateKeyPairRSA = require('../assets/icons/error.svg')
          this.generateKeyPairRSAText = this.$i18n.t('Encryption key generation error').toString()
        }
        if (e.Function === 'CreatePKCS10' && e.id === '1') {
          this.catchError = true
          this.textDanger = [3]
          this.textMarginless = [3]
          this.createPKCS10GOST = require('../assets/icons/error.svg')
          this.createPKCS10GOSTText = this.$i18n.t('PKCS#10 request generation error: Signature Algorithm').toString()
        }
        if (e.Function === 'CreatePKCS10' && e.id === '2') {
          this.catchError = true
          this.textDanger = [5]
          this.textMarginless = [5]
          this.createPKCS10RSA = require('../assets/icons/error.svg')
          this.createPKCS10RSAText = this.$i18n.t('PKCS#10 request generation error: Encryption Algorithm').toString()
        }
      }
    },
    TryAgainRefresh () {
      this.tokenPage = false
      this.$emit('update:currentForm', 'FormStepThree')
    },
    TryAgain () {
      this.catchError = false
      this.textGreyLight = [1, 2, 3, 4, 5]
      this.textDanger = []
      this.textPrimary = []
      this.textMarginless = []
      this.allChecked = false
      this.tryToken = require('../assets/icons/check_silver.svg')
      this.generateKeyPairGOST = require('../assets/icons/check_silver.svg')
      this.createPKCS10GOST = require('../assets/icons/check_silver.svg')
      this.generateKeyPairRSA = require('../assets/icons/check_silver.svg')
      this.createPKCS10RSA = require('../assets/icons/check_silver.svg')
      this.tryTokenText = this.$i18n.t('Security token preparation').toString()
      this.generateKeyPairGOSTText = this.$i18n.t('Signature key generation').toString()
      this.createPKCS10GOSTText = this.$i18n.t('PKCS#10 request generation').toString()
      this.generateKeyPairRSAText = this.$i18n.t('Encryption key generation').toString()
      this.createPKCS10RSAText = this.$i18n.t('PKCS#10 request generation').toString()
      this.tryTokenHelpText = null
      this.createPKCS10GOSTHelpText = null
      this.generateKeyPairRSAHelpText = null
      this.createPKCS10RSAHelpText = null
      this.checkAll()
    }
  },
  watch: {
    devices: {
      handler: function (val) {
        console.log(this.devices)
        console.log(val)
      }
    },
    allChecked: {
      handler: function (val) {
        if (!val) {
          this.checkAll()
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
  .help-mod {
    width: 620px;
  }
</style>