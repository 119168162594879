<template xmlns:v-validate="http://www.w3.org/1999/xhtml">
  <div>

    <steps :passive-steps="[4, 5, 6, 7]" :active-step="3" :finished-steps="[1, 2]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Step 3. Order form') }}</h1>
        <h2 class="title is-4">{{ $t('Data of the employee') }}, <br> {{ $t('who has the right to participate in e-procurement on the “Samruk-Kazyna” portal') }}</h2>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-6">

              <div class="field">
                <label class="label">{{ $t('Last name') }} ({{ $t('latin letters') }})*</label>
                <div class="control">
                  <input v-model.trim="surname" name="surname" v-validate="'required|min:1|max:30|check'" class="input" type="text" :class="{'has-error': errors.has('surname')}" v-bind:placeholder="$t('from 1 to 30 characters')" :disabled="isDisabled">
                  <p v-if="errors.has('surname')" class="alert-error ">{{errors.first('surname')}}</p>
                </div>
              </div>
              <div style="height: 1rem;"></div>
              <div class="field">
                <label class="label">{{ $t('Name') }} ({{ $t('latin letters') }})*</label>
                <div class="control">
                  <input v-model.trim="name" name="name" v-validate="'required|min:1|max:30|check'" class="input" type="text" :class="{'has-error': errors.has('name')}" v-bind:placeholder="$t('from 1 to 30 characters')" :disabled="isDisabled">
                  <p v-if="errors.has('name')" class="alert-error ">{{errors.first('name')}}</p>
                </div>
              </div>
              <div style="height: 1rem;"></div>
              <div class="field">
                <label class="label">Email*</label>
                <div class="control">
                  <input v-model.trim="email" name="email" v-validate="'required|max:45|email'" class="input" type="email" :class="{'has-error': errors.has('email')}" v-bind:placeholder="$t('Enter your Email')" :disabled="isDisabled">
                  <p v-if="errors.has('email')" class="alert-error ">{{errors.first('email')}}</p>
                </div>
              </div>

              <div v-if="this.role === '1' || this.role === '2'">
                <div style="height: 1rem;"></div>
                <div class="field">
                  <label class="label">{{ $t('Identity Document') }}:</label>
                  <label class="label">{{ $t('Number') }}*</label>
                  <div class="control">
                    <input v-model="IdNumber" name="IdNumber" v-validate="'required'" class="input" type="text" :class="{'has-error': errors.has('IdNumber')}" :disabled="isDisabled">
                    <p v-if="errors.has('IdNumber')" class="alert-error ">{{errors.first('IdNumber')}}</p>
                  </div>
                </div>
                <div class="field">
                  <label class="label">{{ $t('Date of issue') }}*</label>
                  <div class="control">
                    <datepicker :language="dateTimeLocale" v-model="IdDate" name="IdDate" v-validate="'required'" format="d/MM/yyyy" input-class="input" :disabledDates="state.disabledDates"
                                :class="{'has-error': errors.has('IdDate')}" :disabled="isDisabled"></datepicker>
                    <p v-if="errors.has('IdDate')" class="alert-error ">{{errors.first('IdDate')}}</p>
                  </div>
                </div>
                <div class="field">
                  <label class="label">{{ $t('Issued by') }}*</label>
                  <div class="control">
                    <input v-model="IdIssued" name="IdIssued" v-validate="'required'" class="input" type="text" :class="{'has-error': errors.has('IdIssued')}" :disabled="isDisabled">
                    <p v-if="errors.has('IdIssued')" class="alert-error ">{{errors.first('IdIssued')}}</p>
                  </div>
                </div>
                <div class="field">
                  <label class="label">{{ $t('Country of issue') }}*</label>
                  <div class="select is-fullwidth">
                    <select name="country" v-model="selectedCountryId" :disabled="isDisabled">
                      <option v-for="(country, index) in countries" :key="index" :value="country.id">{{country.name}}</option>
                    </select>
                  </div>
                </div>
                <div style="height: 1rem;"></div>
                <div class="field">
                  <label class="label">{{ $t('Scan-copy of the identification document') }}*</label>
                  <div class="file has-name is-primary">
                    <label class="file-label">
                      <input name="scan" id="documentScan" v-validate="!scanIdUser ? (!inptFileNameId ? 'required|fileSize' : '') : (!inptFileNameId ? 'fileSize' : '')" accept=".jpg,.png,.pdf,.tif" type="file" @change="processFile" :disabled="isDisabled" class="file-input" :class="{'has-error': errors.has('IdFile')}">
                      <input name="IdFileName" type="hidden" :value="{inptFileNameId}"/>
                      <input name="IdFileBlob" type="hidden" :value="{inptFileBlobId}"/>
                      <span class="file-cta">
                      <span class="file-icon"><img src="@/assets/icons/attach.svg"></span>
                      <span class="file-label">{{ $t('Attach a document') }}</span>
                    </span>
                      <span class="file-name" :class="{'has-text-danger': isTextDanger, 'has-text-primary': isTextPrimary}">{{IdFileText}}</span>
                    </label>
                    <p class="doc" v-if="scanIdUser"><a v-on:click="getDoc()">{{ $t('Document...') }}</a></p>
                  </div>
                  <p v-if="errors.has('scan')" class="alert-error ">{{errors.first('scan')}}</p>
                </div>
              </div>

              <div style="height: 4rem;"></div>

              <div class="buttons">
                <input class="button is-large" type="button" v-on:click="GoToStepTree" v-bind:value="$t('← Back')">
                <input class="button right is-primary is-large" type="button" v-on:click="GoToStepThreeDotTwo" v-bind:value="$t('Next →')">
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import steps from '../steps/Steps'
import fileSaver from 'file-saver'
import Datepicker from 'vuejs-datepicker'
import { en, ru, kk } from 'vuejs-datepicker/dist/locale'
import { dateFilter } from 'vue-date-fns'
import 'polyfill-array-includes'
require('es6-object-assign').polyfill()

export default {
  name: 'OrderStepThreeDotOne',
  components: {
    steps,
    Datepicker
  },
  props: [
    'currentForm',
    'role',
    'doc',
    'userParams',
    'dataStepThreeDotOne',
    'reissueParams',
    'editOrderParams',
    'setCertForm'
  ],
  data () {
    return {
      en: en,
      ru: ru,
      kk: kk,
      countries: null,
      surname: null,
      name: null,
      email: null,
      IdNumber: null,
      IdDate: null,
      IdIssued: null,
      isDisabled: false,
      isTextDanger: true,
      isTextPrimary: false,
      IdFileText: this.$i18n.t('No document selected').toString(),
      fileSize: null,
      inptFileNameId: null,
      inptFileBlobId: null,
      scanNameUser: null,
      scanIdUser: null,
      selectedCountryId: null,
      state: {
        disabledDates: {
          from: new Date()
        }
      }
    }
  },
  computed: {
    dateTimeLocale () {
      if (this.$i18n.locale == 'ru') {
        return this.ru
      } else if (this.$i18n.locale == 'en') {
        return this.en
      } else {
        return this.kk
      }
    },
    currentCountry () {
      return this.countries ? this.countries.find(item => item.id === this.selectedCountryId) : null
    },
    selectedCountryName () {
      return this.currentCountry ? this.currentCountry.name : null
    },
    templateType () {
      return localStorage.getItem('template')
    }
  },
  watch: {
    countries: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/countries/').then(response => {
            this.countries = response.data
            let re = /\s*_\s*/
            if (this.setCertForm) {
              let country = this.setCertForm.country.split(re)
              this.selectedCountryId = country[0]
            } else if (this.dataStepThreeDotOne) {
              this.selectedCountryId = this.dataStepThreeDotOne.countryId
            } else if (this.editOrderParams) {
              let country = this.editOrderParams.country.split(re)
              this.selectedCountryId = country[0]
            } else if (this.reissueParams) {
              let country = this.reissueParams.country.split(re)
              this.selectedCountryId = country[0]
            } else {
              this.$http.get('/query/get-country/').then(response => {
                if (response.data) {
                  const country = this.countries ? this.countries.find(item => item.name === response.data) : null
                  this.selectedCountryId = country.id
                } else {
                  this.selectedCountryId = '2'
                }
              })
            }
          })
        }
      },
      immediate: true
    },
    surname: {
      handler: function (val) {
        if (val) {
          this.surname = val.toUpperCase()
        }
      }
    },
    name: {
      handler: function (val) {
        if (val) {
          this.name = val.toUpperCase()
        }
      }
    },
    dataStepThreeDotOne: {
      handler: function (val) {
        // console.log('dataStepThreeDotOne', val)
        if (val) {
          let re = /\s*%\s*/
          if (val.fio) {
            let name = val.fio.split(re)
            this.surname = name[0]
            this.name = name[1]
          }
          this.email = val.email
          this.inptFileNameId = val.IdFileName
          this.inptFileBlobId = val.IdFileBlob
          if (val.scanIdUser) {
            this.scanIdUser = val.scanIdUser
            this.$http.post('/certificate/get-scan-name-id', { scan: val.scanIdUser }).then(response => {
              // console.log('scanNameId=' + response.data)
              if (response.data) {
                this.scanNameUser = response.data
                this.IdFileText = this.scanNameUser
                this.isTextDanger = false
                this.isTextPrimary = true
              }
            })
          }
          if (val.IdData) {
            let data = val.IdData.split(re)
            let [day, month, year] = data[1].split('/')
            let revDate = [month, day, year].join('/')

            this.IdNumber = data[0]
            this.IdDate = new Date(revDate)
            this.IdIssued = data[2]
          }
        }
      },
      immediate: true
    },
    userParams: {
      handler: function (val) {
        // console.log('userParams', val)
        if (val) {
          let re = /\s*%\s*/
          let name = val.fio.split(re)
          this.surname = name[0]
          this.name = name[1]
          this.email = val.e_mail
          this.scanIdUser = val.scanid
          this.isDisabled = true
          if (val.iddata) {
            let data = val.iddata.split(re)
            let [day, month, year] = data[1].split('/')
            let revDate = [month, day, year].join('/')
            this.IdNumber = data[0]
            this.IdDate = new Date(revDate)
            this.IdIssued = data[2]
          }
        }
      },
      immediate: true
    },
    reissueParams: {
      handler: function (val) {
        // console.log('reissueParams', val)
        if (val) {
          this.$http.post('/certificate/get-scan-name-id', { scan: val.scanid }).then(response => {
            console.log('scanNameId=' + response.data)
            if (response.data) {
              this.scanNameUser = response.data
              this.IdFileText = this.scanNameUser
              this.isTextDanger = false
              this.isTextPrimary = true
            }
          })
          this.scanIdUser = val.scanid
          let re = /\s*%\s*/
          let name = val.fio.split(re)
          this.surname = name[0]
          this.name = name[1]
          this.email = val.e_mail
          this.isDisabled = true
          if (val.iddata) {
            let data = val.iddata.split(re)

            let [day, month, year] = data[1].split('/')
            let revDate = [month, day, year].join('/')

            this.IdNumber = data[0]
            this.IdDate = new Date(revDate)
            this.IdIssued = data[2]
          }
        }
      },
      immediate: true
    },
    editOrderParams: {
      handler: function (val) {
        // console.log('editOrderParams', val)
        if (val) {
          this.$http.post('/certificate/get-scan-name-id', { scan: val.scanid }).then(response => {
            // console.log('scanNameId=' + response.data)
            if (response.data) {
              this.scanNameUser = response.data
              this.IdFileText = this.scanNameUser
              this.isTextDanger = false
              this.isTextPrimary = true
            }
          })
          this.scanIdUser = val.scanid
          let re = /\s*%\s*/
          let name = val.fio.split(re)
          this.surname = name[0]
          this.name = name[1]
          this.email = val.e_mail
          if (val.iddata) {
            let data = val.iddata.split(re)

            let [day, month, year] = data[1].split('/')
            let revDate = [month, day, year].join('/')

            this.IdNumber = data[0]
            this.IdDate = new Date(revDate)
            this.IdIssued = data[2]
          }
        }
      },
      immediate: true
    },
    setCertForm: {
      handler: function (val) {
        console.log('setCERTform', val)
        if (val && !this.dataStepThreeDotOne) {
          this.$http.post('/certificate/get-scan-name-id', { scan: val.scanid }).then(response => {
            console.log('scanNameId=' + response.data)
            if (response.data) {
              this.scanNameUser = response.data
              this.IdFileText = this.scanNameUser
              this.isTextDanger = false
              this.isTextPrimary = true
            }
          })
          let re = /\s*%\s*/
          let name = val.fio.split(re)
          this.surname = name[0]
          this.name = name[1]
          this.email = val.e_mail
          this.scanIdUser = val.scanid ? val.scanid : null
          if (val.iddata) {
            let data = val.iddata.split(re)

            let [day, month, year] = data[1].split('/')
            let revDate = [month, day, year].join('/')
            this.IdNumber = data[0]
            this.IdDate = new Date(revDate)
            this.IdIssued = data[2]
          }
        }
      },
      immediate: true
    },
    inptFileNameId: {
      handler: function (val) {
        if (val) {
          this.IdFileText = this.inptFileNameId
          this.isTextDanger = false
          this.isTextPrimary = true
        }
      },
      immediate: true
    }
  },
  methods: {
    GoToStepTree () {
      if (this.role === '4') {
        this.$emit('update:currentForm', 'FormStepThreeDotZero')
      } else {
        this.$emit('update:currentForm', 'FormStepThree')
      }
    },
    GoToStepThreeDotTwo () {
      console.log(this.$validator)

      this.$validator.extend('email', {
        getMessage: field => this.$t('checkValid'),
        validate: value => {
          return this.isValidParams()
        }
      })

      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.role === '4') {
            this.$emit('update:currentForm', 'FormStepFour')
            this.$emit('update:sRole', this.role)
          } else if (this.templateType === 'Test') {
            this.$emit('update:currentForm', 'FormStepThreeDotThree')
            this.$emit('update:sRole', this.role)
          } else {
            this.$emit('update:currentForm', 'FormStepThreeDotTwo')
          }
          this.$emit('update:dataStepThreeDotOne', {
            fio: (this.surname && this.name) ? (this.surname + '%' + this.name) : null,
            email: this.email,
            IdData: this.IdNumber + '%' + dateFilter(this.IdDate, 'D/MM/YYYY') + '%' + this.IdIssued + '%' + this.selectedCountryName,
            scanIdUser: this.scanIdUser,
            IdFileName: this.inptFileNameId,
            IdFileBlob: this.inptFileBlobId,
            countryId: this.selectedCountryId
          })
        }
      })
    },
    docScanValidate () {
      let _validFileExtensions = ['.jpg', '.png', '.pdf', '.tif']
      let myInput = document.getElementById('documentScan')
      if (myInput.type == 'file') {
        let sFileName = myInput.value
        if (sFileName.length > 0) {
          let blnValid = false
          for (let j = 0; j < _validFileExtensions.length; j++) {
            let sCurExtension = _validFileExtensions[j]
            if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true
              break
            }
          }
          if (!blnValid) {
            myInput.value = null
            this.scanIdUser = null
            alert(this.$i18n.t('Upload the file in JPG, PNG, TIF, PDF format (file size shall not exceed 2 MB)'))
            myInput.value = null
            return false
          }
        }
      }
      return true
    },
    processFile (event) {
      if (event) {
        this.docScanValidate()
        const file = event.target.files[0]
        this.fileSize = file ? file.size : null
        if (file && file.size <= 2097152) {
          this.IdFileText = file.name
          this.isTextDanger = false
          this.isTextPrimary = true
          const reader = new FileReader()
          reader.onload = e => this.inptFileBlobId = (e.target.result).split(',')[1]
          reader.readAsDataURL(file)
          this.inptFileNameId = file.name
        } else {
          this.IdFileText = this.$i18n.t('No document selected')
          this.inptFileNameId = null
          this.inptFileBlobId = null
          this.isTextDanger = true
          this.isTextPrimary = false
        }
      }
    },
    getDoc () {
      this.$http.post('/certificate/get-scan-id', { scan: this.scanIdUser }, { responseType: 'blob' }).then(response => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          fileSaver.saveAs(blob, this.scanNameUser)
        }
      })
    },
    isValidParams () {
      return this.$http.get('/query/valid/', { params: { email: this.email, name: this.name, surname: this.surname, type: this.role, doc: this.doc } }).then((response) => {
        return response.data
      }).catch((error) => {
        console.log(error)
        return false
      })
    }
  },
  created () {
    this.$validator.extend('check', {
      validate: value => {
        let strongRegex = new RegExp(/^[A-Z -]+$/i)
        return strongRegex.test(value)
      }
    })
    this.$validator.extend('fileSize', {
      validate: value => {
        if (this.fileSize > 2097152) {
          return false
        } else return true
      }
    })
  }
}
</script>

<style scoped lang="scss">
  .right {
    margin-left: 20px;
  }
  .has-user-border {
    border: 1px solid #339968;
  }
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
  .file-name {
    max-width: 700px !important;
  }
  .doc {
    margin-left: 100px;
  }
  .help {
    display: inline-block;
  }
  div.vdp-datepicker input {
    background-color: white;
    border-color: #dbdbdb;
    color: #363636;
    -webkit-box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
    max-width: 100%;
    width: 100%;
  }
</style>
