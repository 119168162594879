<template>
    <div id="applicationTable">
        <div style="margin: 10px 15px 0 0; font-family: Times New Roman !important; font-size: 14px !important; word-wrap: break-word;" border="0">
            <p v-if="role === '1'" style="text-align: center;" v-html="$t('Application for issue the Registration Certificates from the legal entity')"></p>
            <p v-if="role === '2'" style="text-align: center;" v-html="$t('Application for issue the Registration Certificates from the individual (individual entrepreneur)')"></p>
            <p>{{ $t('Order number') }}: {{order}}</p>
            <p v-if="role === '1'">{{ $t('Organization identification number') }}: {{docValue}}</p>
            <p v-if="role === '2'">{{  $t('Taxpayer Identification Number') }}: {{docValue}}</p>
            <p v-if="role === '1'">{{ $t('Company name') }}: {{orgName}}</p>
            <p v-if="role === '2'">{{ $t('Individual entrepreneur name') }}: {{orgName}}</p>
            <p v-if="role === '1' || role === '2'">{{ $t('Number of an identity document, date of issue, name of issuing authority with an indication of the state of issue or unique number') }}: {{IdData}}</p>
            <p>{{ $t('Last name') }}: {{surname}}</p>
            <p>{{ $t('Name') }}: {{name}}</p>
            <p v-if="region">{{ $t('Region') }}: {{region}}</p>
            <p>{{ $t('City') }}: {{city}}</p>
            <p>{{ $t('Email app') }}: {{email}}</p>
            <p>{{ $t('Registration Certificate validity period: 365 days') }}</p>
            <p>{{ $t('Information about the sphere of application and restrictions of digital signature usage') }}:</p>
            <table  style="font-family: Times New Roman !important; font-size: 14px !important;" border="0">
                <tr>
                    <td width="70px">{{ $t('Flag') }}</td>
                    <td>{{ $t('Key application') }}</td>
                </tr>
                <tr>
                    <td>0x80</td>
                    <td>{{ $t('Digital Signature') }}</td>
                </tr>
                <tr>
                    <td>0x40</td>
                    <td>{{ $t('Non-Repudiation') }}</td>
                </tr>
                <tr>
                    <td>0x20</td>
                    <td>{{ $t('Key Encipherment') }}</td>
                </tr>
                <tr>
                    <td>0x10</td>
                    <td>{{ $t('Data Encipherment') }}</td>
                </tr>
                <tr>
                    <td>0x08</td>
                    <td>{{ $t('Key Agreement') }}</td>
                </tr>
                <tr>
                    <td>0x04</td>
                    <td>{{ $t('Certificate Signing') }}</td>
                </tr>
                <tr>
                    <td>0x02</td>
                    <td>{{ $t('CRL Signing') }}</td>
                </tr>
            </table>
            <p>{{ $t('Data about the means of the electronic digital signature') }}</p>
            <p>{{ $t('Public key') }}: {{PKCS10}}</p>
            <p>{{ $t('Additional information') }}: ______________________________________________________________________________</p>
            <p>{{ $t('Date') }}: {{ date | moment("DD MMMM YYYY") }}</p>
            <p v-if="role === '1'">{{ $t('Signature of the employee of the legal entity (legal representative)') }}: _________________</p>
            <p v-if="role === '2'">{{ $t('Signature of the employee of the legal entity (individual entrepreneur representative)') }}: _________________</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Application',
  props: [
    'params'
  ],
  data () {
    return {
      surname: null,
      name: null,
      docValue: null,
      order: null,
      region: null,
      city: null,
      email: null,
      IdData: null,
      role: null,
      PKCS10: null,
      orgName: null
    }
  },
  watch: {
    params: {
      handler: function (val) {
        if (val) {
          let re = /\s*%\s*/
          let name = val.fio.split(re)
          this.surname = name[0]
          this.name = name[1]
          this.IdData = val.IdData ? val.IdData.replace(/%/g, ', ') : ''
          this.docValue = val.docValue
          this.order = val.order
          this.region = val.region
          this.city = val.city
          this.email = val.email
          this.role = val.role
          this.PKCS10 = val.PKCS10
          // this.orgName = val.legal ? (val.legal + ' ' + val.orgName) : val.orgName
          this.orgName = val.orgName
        }
      },
      immediate: true
    }
    //    orderId: {
    //      handler: function (val) {
    //        if (!val) {
    //          this.$http.get('/query/get-order-id/', { params: { order: this.params.order} }).then(response => {
    //            if (response.data) {
    //              this.orderId = response.data
    //              this.$http.post('/certificate/get-pkcs10/', { orderId: this.orderId } ).then(response => {
    //                if (response.data) {
    //                  this.PKCS10 = response.data[0].pkcs
    //                }
    //              })
    //            }
    //          })
    //        }
    //      },
    //      immediate: true
    //    }
  },
  computed: {
    date () {
      return new Date()
    }
  }
}
</script>

<style scoped>
  #applicationTable {
    display: none;
  }
</style>
