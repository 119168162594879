<template>
  <div>

    <steps :passive-steps="[5, 6, 7]" :active-step="4" :finished-steps="[1, 2, 3]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Step 4. Keys are being formed') }}</h1>
        <h2 class="title is-4">{{ $t('Initiating the key issue procedure') }}</h2>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-7">
              <p><b>{{ $t('Attention! Before starting the procedure, make sure that the USB port of your computer is connected with only one security token and do not remove it before the end of operation') }}.</b></p>
              <p class="has-text-danger"><b>{{ $t('Not recommended to change the password') }}!</b></p>
              <div style="height: 3rem;"></div>
              <div class="buttons">
                <input class="button is-large" type="button" v-on:click="GoToStepThreeDotThree" v-bind:value="$t('← Back')">
                <input class="button right is-primary is-large" type="button" v-on:click="GoToStepFourDotOne" v-bind:value="$t('Next →')">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import steps from '../steps/Steps'
export default {
  name: 'OrderStepFour',
  components: {
    steps
  },
  props: [
    'currentForm',
    'checkboxDocs',
    'sRole'
  ],
  methods: {
    GoToStepThreeDotThree () {
      if (this.sRole === '4') {
        this.$emit('update:currentForm', 'FormStepThreeDotOne')
      } else {
        this.$emit('update:currentForm', 'FormStepThreeDotThree')
      }
    },
    GoToStepFourDotOne () {
      this.$emit('update:currentForm', 'FormStepFourDotOne')
    }
  }
}
</script>

<style scoped lang="scss">
  .right {
    margin-left: 20px;
  }
</style>
