<template>
  <div>

    <steps :passive-steps="[6, 7]" :active-step="5" :finished-steps="[1, 2, 3, 4]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 v-if="page === '1' || page === '2' || page === '3'" class="title is-3 is-marginless">{{ $t('Keys are successfully generated. Your order is pending at the operator') }}</h1>
        <h1 v-if="page === '4'" class="title is-3 is-marginless">{{ $t('Keys are successfully generated. Your order is pending at the administrator') }}</h1>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-8">
              <article class="message is-primary">
                <div class="message-header">
                  <p>{{ $t('Information about the order') }}</p>
                </div>
                <div class="message-body has-user-border">
                  <p>1. {{ $t('Your order number', [order]) }}.</p>
                  <p>2. {{ $t('An email with a link to order information is sent to your Email address') }}.</p>
                  <p v-if="page === '1' || page === '2'">3. {{ $t('After processing the order') }}.</p>
                  <p v-if="page === '3' || page === '4'">3. {{ $t('Once the request is processed by the administrator, an email with a link to install the EDS key certificate will be sent to your Email address') }}.</p>
                  <p v-if="page === '1' || page === '2'">4. {{ $t('Upon receipt of payment to the account') }}.</p>
                  <p><b>{{ $t('Please do not place a new order until your current order has been processed') }}. {{ $t('Please do not lose the security token, otherwise you will not be able to complete the procedure') }}.</b></p>
                  <p v-html="$t('Under no circumstances the information about your secrete key will be available to anyone but you', [$t('the recommended')])"></p>
                  <table>
                    <tbody>
                    <tr>
                      <td class="is-3"><strong>{{ $t('Information about the order') }}</strong></td>
                      <td class="is-9"></td>
                    </tr>
                    <tr>
                      <td class="is-3">{{ $t('Order number') }}</td>
                      <td class="is-9">{{order}}</td>
                    </tr>
<!--                    <tr v-if="role !== '3'">-->
<!--                      <td>{{ $t('Company name') }}</td>-->
<!--                      <td>{{orgName}}</td>-->
<!--                    </tr>-->
                    <tr v-if="role == '1' || role == '3'">
                      <td>{{ $t('Company name') }}</td>
                      <td>{{orgName}}</td>
                    </tr>
                    <tr v-if="role == '2'">
                      <td>{{ $t('Individual entrepreneur name') }}</td>
                      <td>{{orgName}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Order status') }}</td>
                      <td>{{computedStatus}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Full name') }}</td>
                      <td>{{fio}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Email') }}</td>
                      <td>{{email}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </article>

              <router-link class="button is-primary is-large is-flex-mobile" to="/">{{ $t('Go back to the main page') }}</router-link>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import steps from '../steps/Steps'
export default {
  name: 'OrderStepFive',
  components: {
    steps
  },
  props: [
    'currentForm',
    'paramsForStepFive',
    'status',
    'page'
  ],
  computed: {
    lang () {
      return this.$i18n.locale
    },
    order () {
      return this.paramsForStepFive ? this.paramsForStepFive.order : null
    },
    orgName () {
      // return this.paramsForStepFive ? (this.paramsForStepFive.legal ? (this.paramsForStepFive.legal + ' ' + this.paramsForStepFive.orgName) : this.paramsForStepFive.orgName) : null
      return this.paramsForStepFive ? this.paramsForStepFive.orgName : null
    },
    role () {
      return this.paramsForStepFive ? this.paramsForStepFive.role : null
    },
    fio () {
      return this.paramsForStepFive ? this.paramsForStepFive.fio : null
    },
    phone () {
      return this.paramsForStepFive ? this.paramsForStepFive.phone : null
    },
    email () {
      return this.paramsForStepFive ? this.paramsForStepFive.email : null
    },
    computedStatus () {
      return this.status === '1' ? this.$i18n.t('Received') : this.$i18n.t('Paid')
    }
  }
}
</script>

<style scoped>
  .has-user-border {
    border: 1px solid #339968;
  }
</style>
