<template>
<div>

  <steps :passive-steps="[2, 3, 4, 5, 6, 7]" :active-step="1" :finished-steps="[]"/>

  <section class="section">
    <div class="container has-text-centered">
      <h1 class="title is-3 is-marginless">{{ $t('Step 1') }}. {{ $t('Checking your settings') }}</h1>
    </div>
  </section>

  <section class="section">
    <div class="container">
      <div class="content">
        <div class="columns is-centered">
          <div class="column is-8">
            <div class="media">
              <div class="media-content">
                <p :class="{'has-text-grey-light': isTextGreyLight(1), 'has-text-danger': isTextDanger(1), 'has-text-primary': isTextPrimary(1), 'is-marginless': isTextMarginless(1)}">
                  {{OsText}}
                </p>
                <p class="help help-mod">{{OsHelpText}}</p>
              </div>
              <div class="media-right">
                <img :src="checkOs" width="32">
              </div>
            </div>
            <div class="media">
              <div class="media-content">
                <p :class="{'has-text-grey-light': isTextGreyLight(2), 'has-text-danger': isTextDanger(2), 'has-text-primary': isTextPrimary(2), 'is-marginless': isTextMarginless(2)}" v-html="BrowserText"></p>
                <p class="help help-mod" v-html="BrowserHelpText"></p>
              </div>
              <div class="media-right">
                <img :src="checkBrowser" width="32">
              </div>
            </div>
            <div class="media">
              <div class="media-content">
                <p :class="{'has-text-grey-light': isTextGreyLight(4), 'has-text-danger': isTextDanger(4), 'has-text-primary': isTextPrimary(4), 'is-marginless': isTextMarginless(4)}">
                  {{SoftwareText}}
                </p>
                <p class="help help-mod" v-html="SoftwareHelpText"></p>
              </div>
              <div class="media-right">
                <img :src="checkSoftware" width="32">
              </div>
            </div>
            <div class="media">
              <div class="media-content">
                <p :class="{'has-text-grey-light': isTextGreyLight(3), 'has-text-danger': isTextDanger(3), 'has-text-primary': isTextPrimary(3), 'is-marginless': isTextMarginless(3)}">
                  {{TokenText}}
                </p>
                <TokenHelpText v-if="tokenPage" param="step"/>
                <p class="help help-mod" v-html="TokenHelpText"></p>
              </div>
              <div class="media-right">
                <img :src="checkToken" width="32">
              </div>
            </div>


            <div style="height: 2em;"></div>

            <div class="media">
              <div class="media-content">
                <label class="checkbox">
                  <input type="checkbox" name="PKI Root Certificates" v-model="isChecked" v-validate="'checkbox'" :class="{'has-error': errors.has('PKI Root Certificates')}">
                  <span v-html="$t('Confirm that', [$t('PKI Root certificates')])"></span>
                </label>
                <p class="help help-mod"></p>
                <p v-if="errors.has('PKI Root Certificates')" class="alert-error ">{{errors.first('PKI Root Certificates')}}</p>
              </div>
              <div class="media-right">
                <img :src="checkbox" width="32">
              </div>
            </div>

            <div v-if="catchErrorRefresh">
              <div style="height: 2em;"></div>
              <input class="button is-primary is-medium" type="button" v-on:click="TryAgainRefresh" v-bind:value="$t('Try again')">
            </div>
            <div v-if="catchError">
              <div style="height: 2em;"></div>
              <input class="button is-primary is-medium" type="button" v-on:click="TryAgain" v-bind:value="$t('Try again')">
            </div>

            <div v-if="!catchError && !catchErrorRefresh">
              <div style="height: 2em;"></div>
              <input class="button right is-primary is-large" type="button" v-on:click="GoToStepTwo" :disabled="!valid" v-bind:value="$t('Next →')">
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import steps from '@/steps/Steps'
import TokenHelpText from '../steps/TokenHelpPage'
import { detect } from 'detect-browser'
export default {
  name: 'OrderStepOne',
  components: {
    steps,
    TokenHelpText
  },
  props: [
    'sRole'
  ],
  data () {
    return {
      checkukazatel:null,
      socketState: null,
      isChecked: null,
      valid: false,
      allChecked: false,
      textGreyLight: [1, 2, 3, 4, 5],
      textDanger: [],
      textPrimary: [],
      textMarginless: [],
      checkOs: require('../assets/icons/check_silver.svg'),
      checkBrowser: require('../assets/icons/check_silver.svg'),
      checkToken: require('../assets/icons/check_silver.svg'),
      checkSoftware: require('../assets/icons/check_silver.svg'),
      checkbox: require('../assets/icons/check_silver.svg'),
      OsTextVal: null,
      OsHelpText: null,
      BrowserTextVal: null,
      BrowserHelpText: null,
      SoftwareTextVal: null,
      SoftwareHelpText: null,
      TokenTextVal: null,
      TokenHelpText: null,
      devices: null,
      reader: null,
      RSASupport: false,
      catchError: false,
      catchErrorRefresh: false,
      objectsRSA: null,
      tokenPage: null
    }
  },
  computed: {
    isSocket () {
      return this.$store.state.socket
    },
    isSocketKey () {
      return this.$store.state.socketKey
    },
    lang () {
      return this.$i18n.locale
    },
    browser () {
      return detect()
    },
    software () {
      return this.browser.os.split(' ')[1]
    },
    OsText () {
      if (this.OsTextVal === '1') {
        return this.$i18n.t('Your OS is successfully defined').toString()
      } else if (this.OsTextVal === '2') {
        return this.$i18n.t('Your OS is not supported').toString()
      } else {
        return this.$i18n.t('Checking your OS').toString()
      }
    },
    BrowserText () {
      if (this.BrowserTextVal === '1') {
        return this.$i18n.t('Your browser is successfully defined').toString()
      } else if (this.BrowserTextVal === '2') {
        return this.$i18n.t('Your browser is not supported').toString()
      } else {
        return this.$i18n.t('Checking your browser').toString()
      }
    },
    SoftwareText () {
      if (this.SoftwareTextVal === '1') {
        return this.$i18n.t('The security token software is not detected').toString()
      } else if (this.SoftwareTextVal === '2') {
        return this.$i18n.t('The security token software is detected').toString()
      } else {
        return this.$i18n.t('Checking whether the software necessary for security token operation is installed').toString()
      }
    },
    TokenText () {
      if (this.TokenTextVal === '1') {
        return this.$i18n.t('Please install the correct version of the ”CryptoSocket” software').toString()
      } else if (this.TokenTextVal === '2') {
        return this.$i18n.t('Please make sure the ”CryptoSocket” software is running').toString()
      } else if (this.TokenTextVal === '3') {
        return this.$i18n.t('Found more than one security token').toString()
      } else if (this.TokenTextVal === '4') {
        return this.$i18n.t('The security token is not supported').toString()
      } else if (this.TokenTextVal === '5') {
        return this.$i18n.t('The security token is supported').toString()
      } else if (this.TokenTextVal === '6') {
        return this.$i18n.t('Security token not found or not supported').toString()
      } else {
        return this.$i18n.t('Checking your security token').toString()
      }
    },

    reissue () {
      return localStorage.getItem('order') ? localStorage.getItem('order') : ''
    }
  },
  watch: {
    allChecked: {
      handler: function (val) {
        if (!val) {
          this.checkAll()
        }
      },
      immediate: true
    },
    isChecked: {
      handler: function (val) {
        if (val) {
          this.checkbox = require('../assets/icons/check_green.svg')
          if (this.allChecked) {
            this.valid = true
          }
        } else {
          this.checkbox = require('../assets/icons/check_silver.svg')
          this.valid = false
        }
      },
      immediate: true
    }
  },
  methods: {
    isTextGreyLight (id) {
      return this.textGreyLight.find(text => text === id)
    },
    isTextDanger (id) {
      return this.textDanger.find(text => text === id)
    },
    isTextPrimary (id) {
      return this.textPrimary.find(text => text === id)
    },
    isTextMarginless (id) {
      return this.textMarginless.find(text => text === id)
    },
    GoToStepTwo () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          // this.objectsRSA = JSON.parse(this.objectsRSA.objects)
          // this.objectsRSA = this.objectsRSA.objects
          let objName = null
          let certificate = null
          for (let i = 0; i < this.objectsRSA.length; i++) {
            if ((this.objectsRSA[i].name).indexOf('G') === 0) {
              objName = this.objectsRSA[i].name
              if (this.objectsRSA[i].cert_b64) {
                certificate = this.objectsRSA[i].cert_b64
                break
              }
            }
          }
          if (certificate) {
            this.$emit('update:certificateName', objName)
          }
          if (this.sRole === '4') {
            this.$emit('update:currentForm', 'FormStepThreeDotZero')
          } else if (this.reissue) {
            this.$emit('update:currentForm', 'FormStepThree')
          } else {
            this.$emit('update:currentForm', 'FormStepTwo')
          }
          this.$store.commit('updateOrderStep', '')
        }
      })
    },
    PromiseCheckOs () {

      return new Promise((resolve, reject) => {
        this.checkOs = require('../assets/icons/loading.svg')
        this.textGreyLight = [2, 3, 4]
        const vm = this
        setTimeout(() => {
          if (parseInt(vm.software) >= 7) {
            vm.textPrimary = [1]
            vm.OsTextVal = '1'
            vm.checkOs = require('../assets/icons/check_green.svg')
            resolve(vm.checkOs)
          } else {
            vm.catchError = true
            vm.textDanger = [1]
            vm.textMarginless = [1]
            vm.OsTextVal = '2'
            vm.OsHelpText = vm.$i18n.t('Supported OS').toString()
            vm.checkOs = require('../assets/icons/error.svg')
            reject(vm.OsText)
          }
        }, 2000)
      })
    },
    checkBrowserOk () {
      this.textPrimary = [1, 2]
      this.textGreyLight = [3]
      this.BrowserTextVal = '1'
      this.checkBrowser = require('../assets/icons/check_green.svg')
      this.checkSoftware = require('../assets/icons/loading.svg')
    },
    checkBrowserBad () {
      this.catchError = true
      this.textDanger = [2]
      this.textMarginless = [2]
      this.BrowserTextVal = '2'
      this.BrowserHelpText = this.$i18n.t('Supported browsers').toString()
      console.log(this.$i18n + "====> current locale")
      this.checkBrowser = require('../assets/icons/error.svg')
    },
    PromiseCheckBrowser () {
      return new Promise((resolve, reject) => {
        this.checkBrowser = require('../assets/icons/loading.svg')
        this.textGreyLight = [3, 4]
        const vm = this
        setTimeout(() => {
          if (vm.browser) {
            // console.log(vm.browser.name)
            // console.log(vm.browser.version)
            if (vm.browser.name === 'chrome') {
              if (parseInt(vm.browser.version) >= 49) {
                vm.checkBrowserOk()
                resolve(vm.checkBrowser)
              } else {
                vm.checkBrowserBad()
                reject(vm.BrowserText)
              }
            } else if (vm.browser.name === 'firefox') {
              if (parseInt(vm.browser.version) >= 61) {
                vm.checkBrowserOk()
                resolve(vm.checkBrowser)
              } else {
                vm.checkBrowserBad()
                reject(vm.BrowserText)
              }
            } else if (vm.browser.name === 'ie') {
              vm.checkBrowserBad()
              reject(vm.BrowserText)
            } else if (vm.browser.name === 'edge') {
              vm.checkBrowserOk()
              resolve(vm.checkBrowser)
            } else if (vm.browser.name === 'opera') {
              if (parseInt(vm.browser.version) >= 55) {
                vm.checkBrowserOk()
                resolve(vm.checkBrowser)
              } else {
                vm.checkBrowserBad()
                reject(vm.BrowserText)
              }
            } else {
              vm.checkBrowserBad()
              reject(vm.BrowserText)
            }
          }
        }, 2000)
      })
    },

    PromiseCheckSoftware () {

      return new Promise((resolve, reject) => {
        this.checkSoftware = require('../assets/icons/loading.svg')
        var err = 0;
        this.textGreyLight = [3]
        if (this.objectsRSA) {
          this.objectsRSA = JSON.parse(this.objectsRSA.objects) // Подумай над этим // Он вроде нужен только для шага два
          this.objectsRSA = this.objectsRSA.objects
        }

        const vm = this
        setTimeout(() => {
          for (var i = 0; i < vm.devices.devices.length; i++) {
            if (vm.devices.devices[i].algorithm === 'RSA') {
              vm.RSASupport = true
            }
          }

          if (!vm.RSASupport) {
            // console.log(vm.devices.devices[0].name.indexOf('JaCarta'));
            if (vm.devices.devices[0].name.indexOf('SafeNet 5110') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install “SafeNet Authentication Client” software').toString()
              err = 1;
            } else if (vm.devices.devices[0].name.indexOf('JavaToken') >= 0 || vm.devices.devices[0].name.indexOf('SafeNet Token') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install driver package for eToken PRO 72k (Java)').toString()
              err = 2;
            } else if (vm.devices.devices[0].name.indexOf('JaCarta') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install driver package for JaCarta') +
                  ' - <a href="https://aladdin-rd.ru/support/downloads/jacarta/f327354c-f4b4-4dd3-b560-b94819bfb545" target="_blank">' + this.$i18n.t('”JaCarta Unified Client” software').toString() + '</a>.'
              err = 3;
            } else if (vm.devices.devices[0].name.indexOf('KzToken') >= 0) {
              vm.SoftwareHelpText = this.$i18n.t('It is necessary to install the appropriate KzToken software').toString()
              err = 4;
            }
            vm.catchError = true
            vm.textDanger = [4]
            vm.textMarginless = [4]
            vm.SoftwareTextVal = '1'
            vm.checkSoftware = require('../assets/icons/error.svg')
            console.log('sofrware not checked')
            reject(vm.SoftwareText)
          }
          if(err == 0){
            vm.textPrimary = [1, 2 ,4]
            vm.SoftwareTextVal = '2'
            vm.checkSoftware = require('../assets/icons/check_green.svg')
            resolve(vm.checkSoftware)
          }
        }, 2000)
      })
    },

    PromiseCheckSocketState () {

      return new Promise((resolve, reject) => {
        this.checkToken = require('../assets/icons/loading.svg')
        this.textGreyLight = [5]
        const vm = this
        setTimeout(() => {
          if (vm.isSocket) {
            if (this.isSocketKey) {
              resolve()
            } else {
              vm.catchErrorRefresh = true
              vm.textDanger = [3]
              vm.textMarginless = [3]
              vm.TokenTextVal = '1'
              vm.TokenHelpText = this.$i18n.t('Please install the software', [this.lang])
              vm.checkToken = require('../assets/icons/error.svg')
              reject(vm.checkToken)
            }
          } else {
            vm.catchErrorRefresh = true
            vm.textDanger = [3]
            vm.textMarginless = [3]
            vm.TokenTextVal = '2'
            vm.TokenHelpText = this.$i18n.t('Please install the software', [this.lang])
            vm.checkToken = require('../assets/icons/error.svg')
            reject(vm.checkToken)
          }
        }, 2000)
      })
    },
    PromiseCheckToken () {
      return new Promise((resolve, reject) => {
        this.devices = this.devices.devices
        this.reader = this.devices[0].reader
        if(!this.devices){
          this.catchError = true;
        }
        if (this.devices.length > 2) {
          this.TokenTextVal = '3'
          this.TokenHelpText = this.$i18n.t('Make sure, that only one security token is connected to the PC and try the operation again').toString()
        }
        if (this.devices[0].name === 'KzToken_not_supported' || this.devices[0].reader.indexOf('Rutoken') >= 0) {
          this.TokenTextVal = '4'
        }
        if(this.devices[0].reader == 'SafeNet eToken 5110+ FIPS 0'){
          this.TokenTextVal = '3'
          this.TokenHelpText = this.$i18n.t('SafeNet eToken 5110+ FIPS 0 is not allowed').toString()
        }
        if (this.TokenTextVal) {
          this.catchError = true
          this.textDanger = [3]
          this.textMarginless = [3]
          this.checkToken = require('../assets/icons/error.svg')
          reject(this.TokenText)
        } else {
          this.textPrimary = [1, 2, 3, 4]
          this.TokenTextVal = '5'
          this.checkToken = require('../assets/icons/check_green.svg')
          resolve(this.checkToken)
        }
      })
    },

    PromiseAllChecked () {
      return new Promise(resolve => {
        this.allChecked = true
        if (this.isChecked) {
          this.valid = true
        }
        resolve(this.allChecked)
      })
    },
    async checkAll () {
      try {
        this.checkukazatel = 1;
        this.checkOs = await this.PromiseCheckOs()
        this.checkukazatel = 2;
        this.checkBrowser = await this.PromiseCheckBrowser()
        this.checkukazatel = 3;
        this.devices = await this.cryptoSocket.getDevices({ id: 1 })
        this.checkSoftware = await this.PromiseCheckSoftware()
        this.checkukazatel = 4;
        this.socketState = await this.PromiseCheckSocketState()
        this.checkToken = await this.PromiseCheckToken()
        this.objectsRSA = await this.cryptoSocket.getObjects({ id: 9, reader: this.reader, pass: '', alg: 'RSA' })
        this.allChecked = await this.PromiseAllChecked()
      } catch (e) {
        if (e.Function === 'getDevices') {
          this.catchError = true
          if(this.checkukazatel == 3) {
            this.textDanger = [4]
            this.textMarginless = [4]
            this.SoftwareHelpText = this.$i18n.t('Connect the security token').toString()
            this.checkSoftware = require('../assets/icons/error.svg')
          }else
          if(this.checkukazatel == 4) {
            this.textDanger = [3]
            this.textMarginless = [3]
            this.checkToken = require('../assets/icons/error.svg')
            this.TokenText = this.$i18n.t('Security token not found or not supported').toString()
            this.tokenPage = true
          }
        }
        if (e.Function === 'getObjects') {
          this.objectsRSA = false
          //this.checkSoftware = await this.PromiseCheckSoftware()
          this.textDanger = [3]
          this.textMarginless = [3]
          this.checkToken = require('../assets/icons/error.svg')
          this.TokenText = this.$i18n.t('Security token not found or not supported').toString()
          this.tokenPage = true
        }
      }
    },
    TryAgainRefresh () {
      location.reload()
    },
    TryAgain () {
      this.tokenPage = false
      this.catchError = false
      this.textGreyLight = [1, 2, 3, 4, 5]
      this.textDanger = []
      this.textPrimary = []
      this.textMarginless = []
      this.checkOs = require('../assets/icons/check_silver.svg')
      this.checkBrowser = require('../assets/icons/check_silver.svg')
      this.checkSoftware = require('../assets/icons/check_silver.svg')
      this.checkToken = require('../assets/icons/check_silver.svg')
      this.checkbox = require('../assets/icons/check_silver.svg')
      this.OsTextVal = null
      this.OsHelpText = null
      this.BrowserTextVal = null
      this.BrowserHelpText = null
      this.SoftwareTextVal = null
      this.SoftwareHelpText = null
      this.TokenTextVal = null
      this.TokenHelpText = null

      this.checkAll()
    }
  },
  created () {
    this.$validator.extend('checkbox', {
      getMessage: field => this.$i18n.t('Not confirmed').toString(),
      validate: value => value
    })
  }
}
</script>

<style scoped>
.help-mod {
  width: 800px;
}
.has-error {
  border-color: red;
}
.alert-error {
  color: red;
}
</style>
