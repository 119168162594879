<template>
  <div>

    <steps :passive-steps="[3, 4, 5, 6, 7]" :active-step="2" :finished-steps="[1]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Step 2. Accepting CA Certificate Policies') }}</h1>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-9">
              <article class="message is-primary">
                <div class="message-header">
                  <p>{{ $t('Personal Data Collection and Processing Agreement') }}</p>
                </div>
                <div class="message-body is-scrollable has-user-border">
                  <p>{{$t('infoCondition_1')}}</p>
                  <p>{{$t('infoCondition_2')}}</p>
                  <ul>
                    <li>{{$t('infoCondition_3')}}</li>
                    <li>{{$t('infoCondition_4')}}</li>
                    <li>{{$t('infoCondition_5')}}</li>
                    <li>{{$t('infoCondition_6')}}</li>
                  </ul>
                  <p>{{$t('infoCondition_7')}}</p>
                  <div class="accept-conditions">
                    <p>
                      <label class="checkbox">
                        <input type="checkbox" name="information" v-model="isInformation" v-validate="'Information'" :class="{'has-error': errors.has('information')}">
                        {{ $t('I have read and agree to the terms of this agreement')}}
                      </label>
                    </p>
                    <p v-if="errors.has('information')" class="alert-error ">{{errors.first('information')}}</p>
                  </div>
                </div>
              </article>
              <br>
              <p>
                <label class="checkbox">
                  <input type="checkbox" name="offer" v-model="isOffer" v-validate="'Offer'" :class="{'has-error': errors.has('offer')}">
                  {{ $t('I accept') }} <a v-bind:href="'/application/pdf/' + lang + '/offer.pdf'" target="_blank">{{ $t('Public Offer Agreement') }}</a>
                </label>
              </p>
              <p v-if="errors.has('offer')" class="alert-error ">{{errors.first('offer')}}</p>
              <p>
                <label class="checkbox">
                  <input type="checkbox" name="policy" v-model="isConditions" v-validate="'Conditions'" :class="{'has-error': errors.has('policy')}">
                  {{ $t('I agree with') }} <router-link to="/conditions" target="_blank">{{ $t('offerCheckbox2.1') }}</router-link> {{ $t('and') }} <router-link to="/confedence" target="_blank">{{ $t('offerCheckbox2.2') }}</router-link>
                </label>
              </p>
              <p v-if="errors.has('policy')" class="alert-error ">{{errors.first('policy')}}</p>
              <p>
                <label class="checkbox">
                  <input type="checkbox" name="rules" v-model="isRules" v-validate="'Rules'" :class="{'has-error': errors.has('rules')}">
                  {{ $t('I agree with') }} <a v-bind:href="'/application/pdf/' + lang + '/rules.pdf'" target="_blank">{{ $t('offerCheckbox3.1') }}</a> {{ $t('and') }} <a v-bind:href="'/application/pdf/' + lang + '/policy.pdf'" target="_blank">{{ $t('offerCheckbox3.2') }}</a>
                </label>
              </p>
              <p v-if="errors.has('rules')" class="alert-error ">{{errors.first('rules')}}</p>

              <div style="height:0.75rem;"></div>

              <div class="buttons">
                <input class="button is-large" type="button" v-on:click="GoToStepOne" v-bind:value="$t('← Back')">
                <input class="button right is-primary is-large" type="button" v-on:click="GoToStepTree" v-bind:value="$t('Next →')">
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import steps from '../steps/Steps'
export default {
  name: 'OrderStepTwo',
  components: {
    steps
  },
  data () {
    return {
      isOffer: false,
      isConditions: false,
      isRules: false,
      isInformation: false
    }
  },
  computed: {
    getLang () {
      return this.$route.query.lang ? this.$route.query.lang : null
    },
    lang () {
      return this.getLang ? this.getLang : this.$i18n.locale
    }
  },
  methods: {
    GoToStepOne () {
      this.$emit('update:currentForm', 'FormStepOne')
    },
    GoToStepTree () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('update:currentForm', 'FormStepThree')
        }
      }).catch(error => console.log(error.message))
    }
  },
  created () {
    this.$validator.extend('Offer', {
      getMessage: field => this.$i18n.t('Confirm that you accept the Public Offer Agreement').toString(),
      validate: value => value
    })
    this.$validator.extend('Conditions', {
      getMessage: field => this.$i18n.t('Confirm that you agree with the Terms of Use and Privacy Policy').toString(),
      validate: value => value
    })
    this.$validator.extend('Rules', {
      getMessage: field => this.$i18n.t('Confirm that you agree with CA Rules and Certificate Policies').toString(),
      validate: value => value
    }),
    this.$validator.extend('Information', {
      getMessage: field => this.$i18n.t('Confirm that you agree with the terms of personal data processing').toString(),
      validate: value => value
    })
  }
}
</script>

<style scoped>
  .right {
    margin-left: 20px;
  }
  .is-scrollable {
    overflow: auto;
  }
  .has-user-border {
    border: 1px solid #339968;
  }
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
  .message {
    margin-bottom: 0 !important;
  }
  .accept-conditions {
    text-align: center;
    font-weight: bold;
    padding: 1em 0;
  }
  ul {
    list-style: none;
    margin-left: 20px;
  }
</style>
