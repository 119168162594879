<template xmlns:v-on="http://www.w3.org/1999/xhtml">
    <div>

        <steps :passive-steps="[5, 6, 7]" :active-step="4" :finished-steps="[1, 2, 3]"/>

        <section class="section">
            <div class="container has-text-centered">
                <h1 class="title is-3">{{ $t('Complete the application form for the EDS key certificate') }}</h1>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="content">
                    <div class="columns is-centered">
                        <div class="column is-8">
                            <p class="title is-5">1. {{ $t('Print the application form for the EDS key certificate') }}.</p>
                            <a class="button" @click="print"><span class="file-icon"><img src="@/assets/icons/print.svg"></span><span>{{ $t('Print') }}</span></a>
                            <a class="button" @click="save"><span class="file-icon"><img src="@/assets/icons/download.svg"></span><span>{{ $t('Save') }}</span></a>
                            <p class="help">{{ $t('If there are any printing problems for app') }}.</p>
                            <div style="height: 1rem;"></div>
                            <p class="title is-5 is-marginless">2. {{ $t('Put the handwritten signature of the owner of the EDS key certificate on the printed application form') }}.</p>
                            <div style="height: 1rem;"></div>
                            <p class="title is-5 is-marginless">3. {{ $t('Scan the signed application form for the EDS key certificate') }}.</p>
                            <p class="help">{{ $t('Save the file in JPG, PNG, TIF, PDF format (file size shall not exceed 2 MB)') }}.</p>
                            <div style="height: 1rem;"></div>
                            <p class="title is-5">4. {{ $t('Attach the scanned and signed application form for the EDS key certificate') }}.</p>

                            <div class="file has-name is-primary">
                                <label class="file-label">
                                    <input name="Application" id="documentScan" @change="processFile" class="file-input" accept=".jpg,.png,.pdf,.tif" type="file">
                                    <input name="AppFileName" type="hidden" :value="{inptFileNameApp}"/>
                                    <input name="AppFileBlob" type="hidden" :value="{inptFileBlobApp}"/>
                                    <span class="file-cta">
                                        <span class="file-icon"><img src="@/assets/icons/attach.svg"></span>
                                        <span class="file-label">{{ $t('Attach an application') }}</span>
                                    </span>
                                    <span class="file-name" :class="{'has-text-danger': isTextDanger, 'has-text-primary': isTextPrimary}">{{AppFileText}}</span>
                                </label>
                            </div>

                            <div style="height: 3rem;"></div>

                            <div class="buttons">
                                <input class="button is-primary is-large" type="button" :disabled="!inptFileNameApp" v-on:click="GoToStepFive" v-bind:value="$t('Next →')">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

    <applicationForm :params="paramsForStepFourDotThree"/>

  </div>
</template>

<script>
import steps from '../steps/Steps'
import applicationForm from '../steps/Application'
import fileSaver from 'file-saver'
export default {
  name: 'OrderStepFourDotThree',
  components: {
    steps,
    applicationForm
  },
  props: [
    'currentForm',
    'paramsForStepFourDotThree',
    'dataStepFourDotThree'
  ],
  data () {
    return {
      isTextDanger: true,
      isTextPrimary: false,
      inptFileNameApp: null,
      inptFileBlobApp: null,
      fileLabel: null
    }
  },
  computed: {
    order () {
      return this.paramsForStepFourDotThree ? this.paramsForStepFourDotThree.order : null
    },
    AppFileText () {
      return this.fileLabel ? this.fileLabel : this.$i18n.t('No document selected').toString()
    }
  },
  methods: {
    docScanValidate () {
      let _validFileExtensions = ['.jpg', '.png', '.pdf', '.tif']
      let myInput = document.getElementById('documentScan')
      if (myInput.type == 'file') {
        let sFileName = myInput.value
        if (sFileName.length > 0) {
          let blnValid = false
          for (let j = 0; j < _validFileExtensions.length; j++) {
            let sCurExtension = _validFileExtensions[j]
            if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true
              break
            }
          }
          if (!blnValid) {
            myInput.value = null
            this.inptFileNameApp = null
            alert(this.$i18n.t('Upload the file in JPG, PNG, TIF, PDF format (file size shall not exceed 2 MB)'))
            myInput.value = null
            return false
          }
        }
      }
      return true
    },
    processFile (event) {
      if (event) {
        this.docScanValidate()
        const file = event.target.files[0]
        if (file) {
          if (file.size > 2097152) {
            this.fileLabel = this.$i18n.t('File size should not exceed 2MB')
            this.inptFileNameApp = null
            this.inptFileBlobApp = null
            this.isTextDanger = true
            this.isTextPrimary = false
          } else {
            this.fileLabel = file.name
            this.isTextDanger = false
            this.isTextPrimary = true
            const reader = new FileReader()
            reader.onload = e => this.inptFileBlobApp = (e.target.result).split(',')[1]
            reader.readAsDataURL(file)
            this.inptFileNameApp = file.name
          }
        } else {
          this.fileLabel = this.$i18n.t('No document selected')
          this.inptFileNameApp = null
          this.inptFileBlobApp = null
          this.isTextDanger = true
          this.isTextPrimary = false
        }
      }
    },
    GoToStepFive () {
      this.$validator.validateAll().then((result) => {
        console.log(result)
        if (result) {
          this.$emit('update:currentForm', 'lastForm')
          this.$emit('update:dataStepFourDotThree', {
            AppFileName: this.inptFileNameApp,
            AppFileBlob: this.inptFileBlobApp
          })
        }
      })
    },
    print () {
      const printContents = document.getElementById('applicationTable').innerHTML
      const WinPrint = window.open('', '', 'left=50,top=50,width=900,height=750,toolbar=0,scrollbars=1,status=0')
      WinPrint.document.open()
      WinPrint.document.write(printContents)
      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    save () {
      console.log(this.order);
      const certBody = '<!DOCTYPE><html><head><meta http-equiv="content-type" content="text/html; charset=utf-8"></head><body>' + document.getElementById('applicationTable').innerHTML + '</body></html>'
      this.$http.post('/certificate/pdf-make', { content: certBody, order: this.order }, {
        responseType: 'blob'
      }).then(response => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          fileSaver.saveAs(blob, 'Application_' + this.order + '.pdf')
        }
      })
    },

  }
}

</script>

<style scoped lang="scss">
  .file-name {
    max-width: 700px !important;
  }
  .has-user-border {
    border: 1px solid #339968;
  }
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
  .doc {
    margin-left: 100px;
  }
</style>
